import type { ReactElement } from 'react';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import styles from './vectorModal.module.scss';
import type { CVSSVectorV4 } from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import localeCvss from '../../../../utils/i18n/cvssLocales/vectorMetricsLocale.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCvss;

const macroNames = [
  localeCvss[currentLocale].macronames.exploitability,
  localeCvss[currentLocale].macronames.complexity,
  localeCvss[currentLocale].macronames.vulnerableSystem,
  localeCvss[currentLocale].macronames.subsequentSystem,
  localeCvss[currentLocale].macronames.exploitation,
  localeCvss[currentLocale].macronames.securityRequirements,
];

const cvssMacroVectorValues: Record<string, string> = {
  '0': localeCvss[currentLocale].values.high,
  '1': localeCvss[currentLocale].values.medium,
  '2': localeCvss[currentLocale].values.low,
  '3': localeCvss[currentLocale].values.none,
};

function calculateCvssScoreColor(score: number): string {
  if (score >= 0 && score <= 3.9) return '#00bb00';
  if (score >= 3.9 && score <= 6.9) return '#ffb800';
  if (score >= 6.9 && score <= 8.9) return '#ff6200';
  if (score > 8.9) return '#dc0000';

  return '#000000';
}

export function VectorInfographicsV4({ cvss }: { cvss: CVSSVectorV4 }): ReactElement {
  return (
    <div className={styles.vector_info}>
      <table className={`${styles.scores_info} ${styles.info_block}`}>
        <tr>
          <td className={styles.score_name}>{localeCvss[currentLocale].scores.overall}:</td>
          <td>
            <span>{cvss.score || '0.0'}</span>
          </td>
        </tr>
        <tr>
          <td className={styles.score_name}>{localeCvss[currentLocale].scores.overallRating}:</td>
          <td>
            <span style={{ color: calculateCvssScoreColor(cvss.score), fontWeight: 'bold' }}>
              {cvss.rating}
            </span>
          </td>
        </tr>
        {macroNames.map((mName, i) => (
          <tr>
            <td>{mName}:</td>
            <td>{cvssMacroVectorValues[cvss.macroVector[i]] || 'N/a'}</td>
          </tr>
        ))}
      </table>
      <div className={styles.info_block}>
        <Chart style={{ width: '100px', height: '240px', zIndex: '0' }}>
          <ChartValueAxis>
            <ChartValueAxisItem min={0} max={10} />
          </ChartValueAxis>
          <ChartTitle text="Overall" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={['asd', 'ddd']} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              data={[
                {
                  metricGroupName: 'Overall',
                  score: cvss.score || 0,
                  color: calculateCvssScoreColor(cvss.score || 0),
                },
              ]}
              tooltip={{ visible: true, color: '#33333', background: '#000000' }}
              type="column"
              field="score"
              categoryField="metricGroupName"
              colorField="color"
              gap={1}
            />
          </ChartSeries>
        </Chart>
      </div>
    </div>
  );
}
