/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { regExpSpecSymbols, taskTypesValue2 } from '../../../../utils/helpers/constants';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { SelectTimeTask } from './SelectTimeTask';
import { TaskActions } from './TaskActions';
import { setCurrentLocale } from '../../../../utils/helpers/add-task-helper';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { checkTaskName } from '../../../../utils/helpers/validation';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { UserListForm } from './UserListForm';
import { UserEmailBlockTask } from './UserEmailBlockTask';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { PkgUpdateBlockTask } from './PkgUpdateBlockTask';
import { FormInput } from '../../../common/form/FormInput';
import styles from './NewTask.module.scss';
import { KbUpdateBlockTask } from './KbUpdateBlockTask';
import { TaskScopeArea } from '../../../common/form/TaskScopeArea';
import { AssetsMutableBlock } from '../../../common/form/AssetsMutableBlock';
import { ImportSyncBlockTask } from './ImportSyncBlockTask';
import { ServerListForForm } from '../../Devices/ServerListForForm';
import { useServerContext } from '../../../../hooks/useServerContext';
import { AddLdapGrBlockTask } from './AddLdapGrBlockTask';
import { LdapGrListModal } from './LdapGrListModal';
import { SelectSyncSourceTask } from './SelectSyncSourceTask';
import { PolicyLdapBlockTask } from './PolicyLdapBlockTask';
import { LdapCheckDuplicate } from './LdapCheckDuplicate';

const currentLocale = setCurrentLocale();
export function AddTask(): React.ReactElement {
  const { taskId, selectedBulletinIds, errorMutation, setErrorMutation } = useTaskContext();

  const {
    taskTime,
    taskType,
    selectTaskType,
    datePicker,
    taskName,
    setTaskName,
    taskInventorization,
    taskAudit,
    taskAgentUpdate,
    taskPackageUpdate,
    taskKbUpdate,
    taskGroupReport,
    taskSingleReport,
    taskAuditImage,
    handleExit,
    handleSubmit,
    reportType,
    topN,
    isHtmlFormat,
    isPdfFormat,
    fromDatePickerTaskAction,
    toDatePickerTaskAction,
    taskSource,
    sourceSync,
  } = useTaskAddContext();

  const { isAddUserEmail } = useUserModalContext();

  const { isOpen, hostForm, selectedPkgs, selectedPkgsWl, missedKbs, kbsInWl, setOpen } =
    useHostModalContext();

  const { registryForm, imageForm } = useRegisterContext();

  const { deviceForm } = useNetDeviceContext();

  const { isOpenSrvMutableBlock, isOpenLdapGr, serverForm, ldapGrForm } = useServerContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  return (
    <Dialog
      title={
        taskId
          ? localeNewTask[currentLocale].titleEditTask
          : localeNewTask[currentLocale].titleNewTask
      }
      onClose={handleExit}
      className="small task-add-edit modal"
    >
      <Form
        onSubmitClick={handleSubmit}
        render={(): JSX.Element => (
          <FormElement>
            <div className="form-content">
              <Field
                name="taskName"
                component={FormInput}
                id="name"
                label={localeNewTask[currentLocale].form.taskName}
                onChange={(e): void => {
                  setTaskName(e.target.value);
                }}
                inputValue={taskName}
                className="form-field-wrapper"
                customValidator={checkTaskName}
                type="text"
                classForLabel="required-label"
              />
              <div className="newtask-type">
                <label className="k-label">
                  {localeNewTask[currentLocale].form.typeTask.title}
                </label>
                <DropDownList
                  data={taskTypesValue2[currentLocale]}
                  textField="text"
                  dataItemKey="id"
                  value={taskType.value}
                  onChange={(e): void => selectTaskType(e)}
                  disabled={
                    selectedPkgs !== null ||
                    selectedPkgsWl !== null ||
                    missedKbs !== null ||
                    kbsInWl !== null ||
                    !!taskSource
                  }
                  style={{ zIndex: '101' }}
                />
              </div>
              <SelectTimeTask />
              {taskType.value.id === 'asset_synchronize' && <SelectSyncSourceTask />}
              {taskType.value.id === 'asset_synchronize' && sourceSync === 'ad' && (
                <ImportSyncBlockTask />
              )}
              {taskType.value.id === 'asset_synchronize' && serverForm && sourceSync === 'ad' && (
                <AddLdapGrBlockTask />
              )}
              {taskType.value.id === 'asset_synchronize' &&
                ldapGrForm &&
                ldapGrForm.length > 0 &&
                sourceSync === 'ad' && <PolicyLdapBlockTask />}
              {taskType.value.id === 'asset_synchronize' &&
                ldapGrForm &&
                ldapGrForm.length > 0 &&
                sourceSync === 'ad' && <LdapCheckDuplicate />}
              {taskType.value.id !== 'asset_synchronize' && <TaskActions />}
              {(taskType.value.id === 'asset' ||
                taskType.value.id === 'report' ||
                taskType.value.id !== 'asset_synchronize') && (
                <div className={styles.scopeWrap}>
                  <div className={styles.titleWrap}>
                    <span
                      className={taskType.value.id !== 'asset_synchronize' ? 'required-label' : ''}
                    >
                      {localeNewTask[currentLocale].form.scope}
                    </span>
                    {!selectedPkgs && !selectedPkgsWl && !missedKbs && !kbsInWl && (
                      <Button onClick={(): void => setOpen(true)} type="button">
                        {localeNewTask[currentLocale].form.addAssetScope}
                      </Button>
                    )}
                  </div>
                  <div className={styles.mutableBlockWrap}>
                    <AssetsMutableBlock />
                  </div>
                </div>
              )}
              {(selectedPkgs || selectedPkgsWl) && <PkgUpdateBlockTask />}
              {(missedKbs || kbsInWl) && <KbUpdateBlockTask />}
              {taskType.value.id === 'report' && <UserEmailBlockTask />}
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  ((taskType.value.id === 'asset' || taskType.value.id === 'report') &&
                    (!hostForm || hostForm?.length === 0) &&
                    (!registryForm || registryForm?.length === 0) &&
                    (!imageForm || imageForm?.length === 0) &&
                    (!deviceForm || deviceForm.length === 0)) ||
                  (!taskAudit &&
                    !taskInventorization &&
                    !taskAgentUpdate &&
                    !taskPackageUpdate &&
                    !taskKbUpdate &&
                    !taskGroupReport &&
                    !taskSingleReport &&
                    !taskAuditImage &&
                    taskType.value.id !== 'asset_synchronize') ||
                  taskName === '' ||
                  !taskName.search(regExpSpecSymbols) ||
                  (taskType.value.id === 'report' &&
                    (reportType.value.id === 'top_bulletins' ||
                      reportType.value.id === 'top_vulnerable_assets') &&
                    !topN) ||
                  (taskType.value.id === 'report' &&
                    reportType.value.id === 'differential_audit' &&
                    fromDatePickerTaskAction &&
                    toDatePickerTaskAction &&
                    fromDatePickerTaskAction.getTime() >= toDatePickerTaskAction.getTime()) ||
                  (taskType.value.id === 'report' &&
                    reportType.value.id === 'differential_audit' &&
                    (!fromDatePickerTaskAction || !toDatePickerTaskAction)) ||
                  (taskType.value.id === 'report' && !isPdfFormat && !isHtmlFormat) ||
                  (taskTime === 'startAt' && !datePicker) ||
                  (reportType.value.id === 'bulletin_overview' &&
                    (!selectedBulletinIds || selectedBulletinIds.length === 0)) ||
                  (taskType.value.id === 'asset_synchronize' &&
                    (!serverForm || serverForm.length < 1)) ||
                  (taskType.value.id === 'asset_synchronize' &&
                    (!ldapGrForm || ldapGrForm.length < 1))
                }
              >
                {taskId
                  ? localeNewTask[currentLocale].form.btnEdit
                  : localeNewTask[currentLocale].form.btnAdd}
              </Button>
              <Button type="button" onClick={handleExit}>
                {localeNewTask[currentLocale].form.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      {isOpen && <TaskScopeArea />}
      {isAddUserEmail && <UserListForm />}
      {isOpenSrvMutableBlock && <ServerListForForm parentComponent="addTask" />}
      {isOpenLdapGr && <LdapGrListModal />}
    </Dialog>
  );
}
