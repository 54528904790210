/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { useServerContext } from '../../../../../hooks/useServerContext';
import type { LdapGroupResponse } from '../../../../../types/__generated/on-premise-solution/api/ldapGroupResponse.v1';
import localeNewTask from '../../../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { GroupListForForm } from '../../../../common/form/GroupListForForm';
import styles from '../NewTask.module.scss';

export function CellSelectAssetGroup({
  dataItem: { commonName },
}: Omit<GridCellProps, 'commonName'> & {
  dataItem: LdapGroupResponse;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeNewTask;

  const { ldapGrForm, setCurrentCommonName } = useServerContext();

  const { isOpenGroupList, setOpenGroupList } = useHostModalContext();

  const handleOpenGroupList = (): void => {
    setOpenGroupList(true);
    setCurrentCommonName(commonName);
  };

  const ldapGrValue =
    ldapGrForm?.filter((g) => g.assetGr && g.ldapGroupName === commonName)[0]?.assetGr || undefined;

  return (
    <td
      title={
        !ldapGrForm || !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
          ? localeNewTask[currentLocale].form.ldapTable.grid.newGrTooltip
          : ''
      }
    >
      <Button
        icon="plus"
        fillMode="flat"
        onClick={handleOpenGroupList}
        className={ldapGrValue ? styles.add_button_select : styles.add_button}
        disabled={
          !ldapGrForm || !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
        }
      >
        {ldapGrForm?.find((g) => g.assetGr && g.ldapGroupName === commonName)
          ? ldapGrValue?.name
          : localeNewTask[currentLocale].form.ldapTable.grid.assetGrTd}
      </Button>
      {isOpenGroupList && <GroupListForForm parentComponent="CellSelectAssetGroup" />}
    </td>
  );
}
