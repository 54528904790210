import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useServerContext } from '../../../../hooks/useServerContext';
import type { LdapsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';

export function CellSelectServer({
  dataItem: { name },
  dataItem: { ldapId },
  dataItem: { url },
  dataItem: { credential },
  dataItem: { type },
}: Omit<GridCellProps, 'name' | 'ldapId' | 'url' | 'credential' | 'type'> & {
  dataItem: LdapsDashboardResponse;
}): React.ReactElement {
  const { setServerForm, serverForm } = useServerContext();

  return (
    <td style={{ textOverflow: 'clip' }}>
      <RadioButton
        name="credentialGroup"
        value={ldapId}
        disabled={!credential}
        checked={(serverForm && serverForm[0].ldapId === ldapId) || false}
        onChange={(): void =>
          setServerForm([
            {
              ldapId,
              name,
              url,
              credential,
              type,
            },
          ])
        }
      />
    </td>
  );
}
