import { Filter } from '@progress/kendo-react-data-tools';
import { Dialog } from '@progress/kendo-react-dialogs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '../../common/baseElements/Button';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { FILTER_SERVER_LIST_FORM } from '../../../utils/helpers/constant-serv-filter-host';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import localeDevices from '../../../utils/i18n/devices/devices.json';
import { Input } from '../../common/baseElements/Input';
import { CommonServerList } from '../../common/CommonServerList';
import { useServerList } from '../../../hooks/components/useServerList';
import { useServerContext } from '../../../hooks/useServerContext';
import { handleAddAllServers } from '../../../utils/helpers/add-host-list-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevices;

export function ServerListForForm({
  parentComponent,
}: {
  parentComponent?: string;
}): React.ReactElement {
  const dataForHooks = {
    name: 'url',
    componentName: 'AddServerList',
  };

  const urlParams = getUrlList('AddServerList');

  const {
    addSelectedServers,
    exitServerFromTask,
    serverForm,
    setServerForm,
    delAllServers,
    setOpenSrvMutableBlock,
  } = useServerContext();

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useServerList(urlParams, dataForHooks);

  return (
    <Dialog
      title={localeDevices[currentLocale].form.titleSelectServer}
      onClose={(): void => setOpenSrvMutableBlock(false)}
      className="device-list modal"
    >
      <div className="k-form">
        <div className="form-content select-asset">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className="filter-line">
                <Filter
                  value={filter}
                  onChange={onFilterChange}
                  fields={FILTER_SERVER_LIST_FORM[currentLocale]}
                />
                {filter.filters.length === 0 && (
                  <Input
                    name="customSearch"
                    placeholder={localeNewTask[currentLocale].customSearchUrl}
                    value={customSearch}
                    onChange={(e): void => handleCustomSearch(e)}
                  />
                )}
              </div>
            </IntlProvider>
          </LocalizationProvider>
          {parentComponent !== 'addTask' && (
            <div className="k-form-select-buttons">
              <Button
                onClick={(): void => handleAddAllServers(data?.data, serverForm, setServerForm)}
              >
                {localeNewTask[currentLocale].form.btnAddAllAssets}
              </Button>
              {serverForm && serverForm.length > 0 && (
                <Button onClick={delAllServers}>
                  {localeNewTask[currentLocale].form.btnDelAllServers} ({serverForm?.length})
                </Button>
              )}
            </div>
          )}
          <CommonServerList
            sort={sort}
            data={data}
            page={page}
            total={total}
            pageChange={pageChange}
            setSort={setSort}
            dataForHooks={dataForHooks}
            conditionSort={15}
            parentComponent={parentComponent}
          />
          <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
          {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={!serverForm || serverForm?.length === 0}
            onClick={addSelectedServers}
          >
            {localeNewTask[currentLocale].form.btnAddAsset}{' '}
            {serverForm &&
              serverForm?.length > 0 &&
              `(${serverForm.filter((s) => s.ldapId)?.length})`}
          </Button>
          <Button type="button" onClick={exitServerFromTask}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
