import type { LdapDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import localeUser from '../../../../utils/i18n/userLocales/user.json';

export function TableTServerMetaBlock({
  data,
  currentLocale,
}: {
  data: LdapDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column column-second">
      <tbody>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.accountID}</span>
          </td>
          <td>{data?.accountId}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.accountName}</span>
          </td>
          <td>{data?.account.name}</td>
        </tr>
      </tbody>
    </table>
  );
}
