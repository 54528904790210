/* eslint-disable react/jsx-pascal-case */
import { Dialog } from '@progress/kendo-react-dialogs';
import { useCallback, useLayoutEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '../../baseElements/Button';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { decodeCvss } from '../../../../services/common-service';
import type { DecodedCVSSVector } from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import { ScoresInfoBlock } from './ScoresInfoBlock';
import { BoundaryErrorComponent } from '../../BoundaryErrorComponent';
import { VectorInfographicsV3 } from './VectorInfographicsV3';
import { VectorInfographicsV4 } from './VectorInfographicsV4';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VectorModal(): React.ReactElement {
  const queryClient = useQueryClient();

  const { cvssVector, setCvssVector, setOpen } = useAuditModalContext();

  const handleCloseModal = useCallback((): void => {
    setCvssVector(null);
    setOpen(false);
    // queryClient.invalidateQueries(['decodedVector']);
  }, [setCvssVector, setOpen]);

  useLayoutEffect(() => {
    queryClient.invalidateQueries(['decodedVector']);
  }, []);

  const query = useQuery<DecodedCVSSVector, ApiError>(
    ['decodedVector'],
    () => decodeCvss(cvssVector, currentLocale),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      keepPreviousData: true,
    },
    // {
    //   retry: false,
    // },
  );

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      {query.error ? (
        <Dialog title={`${cvssVector}`} onClose={handleCloseModal} className="vector-modal modal">
          <div className="k-form">
            <div className="form-content">
              <p className="nodata vector-content">
                {localeAuditTask[currentLocale].vectorModal.noData}
              </p>
            </div>
            <div className="k-form-buttons">
              <Button type="button" onClick={handleCloseModal}>
                {localeAuditTask[currentLocale].close}
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog title={`${cvssVector}`} onClose={handleCloseModal} className="vector-modal">
          <div className="k-form">
            <div className="form-content vector-content">
              {(query.data?.cvss?.version === '2.0' ||
                query.data?.cvss?.version === '3.0' ||
                query.data?.cvss?.version === '3.1') && (
                <VectorInfographicsV3 cvss={query.data.cvss} />
              )}
              {query.data?.cvss?.version === '4.0' && (
                <VectorInfographicsV4 cvss={query.data.cvss} />
              )}
              {query.data?.cvss?.version === '2.0' ||
              query.data?.cvss?.version === '3.0' ||
              query.data?.cvss?.version === '3.1' ||
              query.data?.cvss?.version === '4.0' ? (
                <ScoresInfoBlock data={query.data?.cvss} />
              ) : (
                'No data'
              )}
            </div>
            <div className="k-form-buttons">
              <Button type="button" onClick={handleCloseModal}>
                {localeAuditTask[currentLocale].close}
              </Button>
            </div>
          </div>
        </Dialog>
      )}{' '}
    </ErrorBoundary>
  );
}
