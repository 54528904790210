/* eslint-disable jsx-a11y/no-static-element-interactions */
import { getUserData } from '../../../services/local-storage-service';
import type { LdapDashboardResponse } from '../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import localeUser from '../../../utils/i18n/userLocales/user.json';
import { TableFServerMetaBlock } from './tables/TableFServerMetaBlock';
import { TableSServerMetaBlock } from './tables/TableSServerMetaBlock';
import { TableTServerMetaBlock } from './tables/TableTServerMetaBlock';
import styles from './UserList.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;
export function ServerMetaBlock({
  data,
}: {
  data: LdapDashboardResponse | undefined;
}): React.ReactElement {
  const currentUser = getUserData();

  return (
    <div className={styles.serverMetablockWrap}>
      <div className={styles.serverMetablock}>
        <TableFServerMetaBlock data={data} currentLocale={currentLocale} />
        <TableSServerMetaBlock data={data} currentLocale={currentLocale} />
        {currentUser?.role === 'super_admin' && (
          <TableTServerMetaBlock data={data} currentLocale={currentLocale} />
        )}
      </div>
      <div className={styles.serverMetablockDesc}>
        <span className={styles.serverMetablockTitle}>
          {localeUser[currentLocale].connectServers.grid.description2}
        </span>
        {data?.description ? (
          <span className={styles.serverMetablockContent}>{data?.description}</span>
        ) : (
          <span className={styles.serverMetablockContent}>-</span>
        )}
      </div>
    </div>
  );
}
