import type { DecodedCVSSVector } from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import { ScoresInfoBlockV3 } from './ScoresInfoBlockV3';
import { ScoresInfoBlockV4 } from './ScoresInfoBlockV4';

export function ScoresInfoBlock({
  data,
}: {
  data: Exclude<DecodedCVSSVector, undefined>['cvss'];
}): React.ReactElement {
  if (data?.version === '2.0' || data?.version === '3.0' || data?.version === '3.1')
    return <ScoresInfoBlockV3 data={data} />;
  if (data?.version === '4.0') return <ScoresInfoBlockV4 data={data} />;

  return <div>No data</div>;
}
