import type { GridCellProps } from '@progress/kendo-react-grid';
import localeNewTask from '../../../../../utils/i18n/taskLocale/newTask.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function ResultCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className, style } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  let val = '';

  if (field === 'summary') {
    switch (value) {
      case 'Added to existing asset group':
        val = localeNewTask[currentLocale].taskSyncDescription.result.addedInExistingGr;
        break;
      case 'Added to new asset group':
        val = localeNewTask[currentLocale].taskSyncDescription.result.addedInNewGr;
        break;
      case 'Added without asset group':
        val = localeNewTask[currentLocale].taskSyncDescription.result.addedWithoutGr;
        break;
      case 'Not added (duplicate)':
        val = localeNewTask[currentLocale].taskSyncDescription.result.notAddedDuplicate;
        break;
      case 'Not added (license limit)':
        val = localeNewTask[currentLocale].taskSyncDescription.result.notAddedLicense;
        break;
      default:
        val = '';
    }
  }

  return value && value !== '-' && value !== 0 ? (
    <td
      title={className && className.includes('title') ? value : ''}
      className={className || ''}
      style={style}
    >
      {val}
    </td>
  ) : (
    <td className={className || ''}>-</td>
  );
}
