import type {
  CVSSVectorV2,
  CVSSVectorV3,
} from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import styles from './vectorModal.module.scss';
import localeCvss from '../../../../utils/i18n/cvssLocales/vectorMetricsLocale.json';

interface ITest {
  name: string;
  value: string;
  description: string;
  abbreviatedForm: { name: string; value: string };
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCvss;

export function ScoresInfoBlockV3({
  data,
}: {
  data: CVSSVectorV2 | CVSSVectorV3;
}): React.ReactElement {
  // const cvssVersion = parseInt(data.version, 10);
  const cvssVersion: '2' | '3' = data.version.split('.')[0] as '2' | '3';
  function metricsToComponent(submetrics: ITest[]): React.ReactElement {
    return (
      <table className={styles.metric_info_table}>
        {Object.values(submetrics).map((metric: ITest) => (
          <tr>
            <td className={styles.metric_name}>
              <li>
                {metric.abbreviatedForm.name in localeCvss[currentLocale][cvssVersion]
                  ? localeCvss[currentLocale][cvssVersion][metric.abbreviatedForm.name as 'AV'].name
                  : metric.name}{' '}
                ({metric.abbreviatedForm.name}){/* {metric.name} ({metric.abbreviatedForm.name}) */}
              </li>
            </td>
            <td>
              <span className={styles.score_values}>
                {
                  // scoreMetricsData[cvssVersion][metric.abbreviatedForm.name]
                  Object.entries(
                    localeCvss[currentLocale][cvssVersion][metric.abbreviatedForm.name as 'AV']
                      .values,
                  )
                    // .filter((scoreName) => scoreName !== metric.value)
                    .map(([scoreAbbrevName, scoreName]) =>
                      scoreAbbrevName === metric.abbreviatedForm.value ? (
                        <span className={styles.metric_score_value}>
                          {scoreName} ({metric.abbreviatedForm.name}:{metric.abbreviatedForm.value})
                        </span>
                      ) : (
                        <span className="alternative-score-name-info">{scoreName}</span>
                      ),
                    )
                }
              </span>
              <span className={styles.metric_description}>{metric.description}</span>
            </td>
          </tr>
        ))}
      </table>
    );
  }

  const {
    subscore: exSubscore,
    substring: exSubstring,
    ...exploitabilities
  } = data.base.exploitability;
  const { subscore: imSubscore, substring: imSubstring, ...impact } = data.base.impact;
  const {
    score: tScore,
    rating: tRating,
    substring: tSubstring,
    ...temporal
  } = data.temporal || {};
  const {
    subscore: mImSubscore,
    substring: mImSubstring,
    rating: mImRating,
    ...mImpact
  } = data.environmental?.securityRequirements || {};

  const { substring: mSubstring, ...mMetrics } =
    (data.version === '2.0'
      ? data.environmental?.generalModifiers
      : data.environmental?.modifiedBaseMetrics) || {};

  return (
    <div className="base">
      <p className={styles.metric_title}>
        {localeCvss[currentLocale].globalMetricNames.baseMetrics}
      </p>
      <p className="submetric-title">
        {localeCvss[currentLocale].globalMetricNames.exploitabilityMetrics}:
      </p>
      {metricsToComponent(Object.values(exploitabilities))}
      <p className="submetric-title">
        {localeCvss[currentLocale].globalMetricNames.impactMetrics}:
      </p>
      {metricsToComponent(Object.values(impact))}
      {(data.version === '3.0' || data.version === '3.1') && data.base.scope && (
        <>
          <p className="submetric-title">{localeCvss[currentLocale].globalMetricNames.scope}:</p>
          {metricsToComponent(Object.values({ scope: data.base.scope }))}
        </>
      )}
      {data.temporal && (
        <>
          <p className={styles.metric_title}>
            {localeCvss[currentLocale].globalMetricNames.temporalMetrics}
          </p>
          {metricsToComponent(Object.values(temporal))}
        </>
      )}
      {data.environmental && (
        <>
          <p className={styles.metric_title}>
            {localeCvss[currentLocale].globalMetricNames.environmentalMetrics}
          </p>
          <p className="submetric-title">
            {localeCvss[currentLocale].globalMetricNames.impactMetrics}
          </p>
          {metricsToComponent(Object.values(mImpact))}
          <p>{data.version === '2.0' && 'General Modifiers'}</p>
          <p>
            {(data.version === '3.0' || data.version === '3.1') &&
              'Exploitability and Impact Metrics'}
          </p>
          {metricsToComponent(Object.values(mMetrics))}
        </>
      )}
    </div>
  );
}
