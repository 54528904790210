import '../../features/Host/hostComponents/HostDescription.module.scss';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { Button } from '../baseElements/Button';
import localeHostDescription from '../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostActions } from '../../../hooks/components/useHostActions';
import styles from './styles.module.scss';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import type { LdapDashboardResponse } from '../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import { useServerContext } from '../../../hooks/useServerContext';

export function SrvDescriptionContextMenu({
  data,
  currentLocale,
}: {
  data: LdapDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const history = useHistory();

  const { handleEdit, setEdit } = useCredentialContext();

  const onEdit = (id: string | undefined): void => {
    handleEdit(id);
    setEdit(true);
  };

  // useEffect(() => {
  //   if (data && data.sshPort) {
  //     setSshPort(String(data.sshPort));
  //   }
  //   if (data?.type === 'host' && data.smbPort) {
  //     setSmbPort(String(data.smbPort));
  //   }
  //   if (data?.type === 'host' && data.winrmPort) {
  //     setWinrmPort(String(data.winrmPort));
  //   }
  // }, []);

  const { selectCredential } = useHostActions();

  const { deleteCredentialFromSrv } = useServerContext();

  return (
    <div className="groups__menu">
      {data && data.credential && (
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className={`dropdown__content ${styles.cred_menu}`}>
            <Button
              fill="menu"
              icon="edit"
              className={styles.credentials_button}
              style={{ marginTop: '8px' }}
              onClick={(): void => onEdit(data.credential?.credentialId)}
            >
              {localeHostDescription[currentLocale].summary.editCredetial}
            </Button>
            <Button
              fill="menu"
              icon="repeat"
              className={styles.credentials_button}
              onClick={(): void =>
                selectCredential({
                  ldapId: data.ldapId,
                  name: data?.name,
                  accountId: data.accountId,
                  credentialId: data.credential?.credentialId,
                  assetType: 'server',
                  type: data?.type,
                })
              }
            >
              {localeHostDescription[currentLocale].summary.changeCredential}
            </Button>
            <Button
              fill="menu"
              onClick={(): void => deleteCredentialFromSrv(data.ldapId)}
              className={styles.credentials_button}
              icon="delete"
            >
              {localeHostDescription[currentLocale].summary.deleteCredential}
            </Button>
            <div className={styles.break} />
            <Button
              fill="menu"
              icon="credentials"
              style={{ marginBottom: '8px' }}
              onClick={(): void => {
                history.push('/lk/credentials/list');
              }}
              className={styles.credentials_button}
            >
              {localeHostDescription[currentLocale].summary.CredentialList}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
