/* eslint-disable prettier/prettier */
import { Button } from '../../../common/baseElements/Button';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useServerContext } from '../../../../hooks/useServerContext';
import styles from './NewTask.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function AddLdapGrBlockTask(): React.ReactElement {
  const { serverForm, setOpenLdapGr, ldapGrForm } = useServerContext();

  const openLdapGrModal = (): void => {
    if (serverForm) setOpenLdapGr(serverForm[0].ldapId);
  };

  return (
    <div className={styles.ldapGrWrap}>
      <div className={styles.ldapGrAction}>
        {(serverForm && serverForm[0].type && serverForm[0].type === 'ldap') ||
        (ldapGrForm &&
          ldapGrForm.length > 0 &&
          ldapGrForm[0].type &&
          ldapGrForm[0].type === 'ldap') ? (
            <span className="required-label">{localeNewTask[currentLocale].form.scopeLdapGr}</span>
        ) : (
          <span className="required-label">{localeNewTask[currentLocale].form.scopeAdGr}</span>
        )}
        <Button onClick={openLdapGrModal} type="button" disabled={!serverForm}>
          {ldapGrForm && ldapGrForm.length > 0
            ? localeNewTask[currentLocale].form.btnChangeLdap
            : localeNewTask[currentLocale].form.btnAddAsset}
        </Button>
      </div>
    </div>
  );
}
