/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import localeTaskDescription from '../../../../utils/i18n/taskLocale/taskDescription.json';
import { setAssetGroupData } from '../../../../utils/helpers/task-helpers';
import type {
  TaskAssetSynchronizeExecutionDashboardResponse,
  TaskExecutionDashboardResponse,
  TaskImageExecutionDashboardResponse,
  TaskReportExecutionDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { ScopeElement } from './ScopeElement';
import { ScopeNotification } from './ScopeNotification';
import styles from '../TaskList.module.scss';

const currentLocale =
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru'
    ? 'ru-RU'
    : ('en-EN' as keyof typeof localeTaskDescription);

export function HistoryScope(props: GridCellProps): React.ReactElement {
  const [isShow, setShow] = useState(false);

  const { dataItem } = props;

  const {
    scope,
    taskType,
  }:
    | TaskExecutionDashboardResponse
    | TaskReportExecutionDashboardResponse
    | TaskImageExecutionDashboardResponse
    | TaskAssetSynchronizeExecutionDashboardResponse = dataItem;

  const scoreData = setAssetGroupData(scope);

  const data = scoreData.assets
    ?.map((asset) => ({
      id: asset.assetId,
      name:
        // eslint-disable-next-line no-nested-ternary
        'imageNames' in asset && asset.imageNames?.length
          ? asset.imageNames[0]
          : 'hostname' in asset
          ? asset.hostname
          : null,
      // 'imageNames' in asset && asset.imageNames && Array.isArray(asset.imageNames)
      //   ? asset.imageNames[0]
      //   : 'hostname' in asset
      //   ? asset.hostname
      //   : '',
      // asset.hostname,
      taskType: taskType as string,
      deviceType: asset.type,
    }))
    .concat(
      scoreData.assetGroups?.map((assetGroup) => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.name,
        taskType: 'assetgroup',
        deviceType: undefined,
      })) || [],
    )
    .concat(
      scoreData.registries?.map((asset) => ({
        id: asset.registryId,
        name: asset.name,
        taskType: 'registry',
        deviceType: undefined,
      })) || [],
    );

  return (
    <td>
      <div className={styles.assetscope}>
        {/* <HistoryScopeNotification scoreData={scoreData} taskType={taskType} isShow={isShow} /> */}
        {data && data.length > 3 ? (
          <>
            <div className={styles.assets}>
              {data?.slice(0, 3).map((se) => (
                <ScopeElement element={se} />
              ))}
            </div>
            <span
              onMouseEnter={(): void => setShow(true)}
              onMouseLeave={(): void => setShow(false)}
            >
              <ScopeNotification
                className="scope-notification"
                data={data}
                isShow={isShow}
                sliceValue={3}
              />
              {localeTaskDescription[currentLocale].summary.andMore}
              {data.length - 3}
            </span>
          </>
        ) : !data || data.length === 0 ? (
          <div>
            <span>-</span>
          </div>
        ) : (
          data?.map((se) => <ScopeElement element={se} />)
        )}
      </div>
    </td>
  );
}
