/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataLevel = [] as IDaraFilterSelect[];
export function FilterCredentialSelect(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataLevel = [
    {
      text: currentLocale === 'ru-RU' ? 'Тип...' : 'Type...',
      val: '',
    },
    { text: currentLocale === 'ru-RU' ? 'ssh' : 'ssh', val: 'ssh' },
    { text: currentLocale === 'ru-RU' ? 'winrm' : 'winrm', val: 'winrm' },
    {
      text: currentLocale === 'ru-RU' ? 'container_registry' : 'container_registry',
      val: 'container_registry',
    },
    {
      text: currentLocale === 'ru-RU' ? 'hashicorp_vault_token' : 'hashicorp_vault_token',
      val: 'hashicorp_vault_token',
    },
    {
      text: currentLocale === 'ru-RU' ? 'hashicorp_vault_userpass' : 'hashicorp_vault_userpass',
      val: 'hashicorp_vault_userpass',
    },
    { text: currentLocale === 'ru-RU' ? 'snmp' : 'snmp', val: 'snmp' },
    { text: currentLocale === 'ru-RU' ? 'ldap' : 'ldap', val: 'ldap' },
    {
      text: currentLocale === 'ru-RU' ? 'activedirectory' : 'activedirectory',
      val: 'activedirectory',
    },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={filter.value ? dataLevel.find((d) => d.val === filter.value) : dataLevel[0]}
      data={dataLevel}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
