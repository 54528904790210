/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function FilterServerType(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const dataStatus = [
    { text: currentLocale === 'ru-RU' ? 'Выбрать тип...' : 'Select type"...', val: '' },
    { text: currentLocale === 'ru-RU' ? 'LDAP' : 'LDAP', val: 'ldap' },
    { text: currentLocale === 'ru-RU' ? 'AD' : 'AD', val: 'activedirectory' },
  ] as IDaraFilterSelect[];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataStatus[0]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
