import { NavLink } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { concat } from '@progress/kendo-data-query/dist/npm/transducers';
import type {
  IActionsValues,
  IBulletinSpecific,
  ICellActions,
} from '../../../../../utils/helpers/types';
import localeTask from '../../../../../utils/i18n/taskLocale/task.json';
import { TaskType } from '../../common/TaskType';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function CellActionSuccess({
  assets,
  assetId,
  type,
  status,
  auditId,
  inventorizationId,
  taskActionId,
  taskExecutionId,
  dataItem,
}: ICellActions): React.ReactElement {
  return (
    <>
      {status === 'success' &&
        type === 'audit' &&
        auditId &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="audit" />{' '}
            <NavLink className="link-underline" to={`/lk/task/actions/${auditId}/audit`}>
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname
                  ? assets[0].latestInventorization?.hostname
                  : assetId}
              </span>
            </NavLink>
          </>
        )}
      {status === 'success' && type === 'audit' && auditId && assets && assets[0].type === 'image' && (
        <>
          <TaskType title type="audit" />{' '}
          <NavLink className="link-underline" to={`/lk/task/actions/${auditId}/audit`}>
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {assets[0].imageNames ? assets[0].imageNames[0] : assetId}
            </span>
          </NavLink>
        </>
      )}
      {status === 'success' &&
        type === 'audit' &&
        auditId &&
        assets &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="audit" />{' '}
            <NavLink className="link-underline" to={`/lk/task/actions/${auditId}/audit`}>
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname
                  ? assets[0].latestInventorization?.hostname
                  : assetId}
              </span>
            </NavLink>
          </>
        )}
      {status === 'success' &&
        type === 'package_update' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="package_update" />{' '}
            <span>
              <NavLink
                className="link-underline"
                to={`/lk/task/actions/${taskActionId}/package_update/result`}
              >
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'success' &&
        type === 'package_update' &&
        assets &&
        assets[0].type === 'host' &&
        !assets[0].latestInventorization && (
          <>
            <TaskType title type="package_update" />
            <span>
              <NavLink
                className="link-underline"
                to={`/lk/task/actions/${taskActionId}/package_update/result`}
              >
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].assetId}
              </NavLink>
            </span>
          </>
        )}
      {status === 'success' && type === 'package_update' && !assets && (
        <>
          <TaskType title type="package_update" />
          <span>
            <NavLink
              className="link-underline"
              to={`/lk/task/actions/${taskActionId}/package_update/result`}
            >
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} {assetId}
            </NavLink>
          </span>
        </>
      )}
      {status === 'success' &&
        type === 'kb_install' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="kb_install" />{' '}
            <span>
              <NavLink
                className="link-underline"
                to={`/lk/task/actions/${taskActionId}/kb_install/result`}
              >
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'success' &&
        type === 'kb_install' &&
        assets &&
        assets[0].type === 'host' &&
        !assets[0].latestInventorization && (
          <>
            <TaskType title type="kb_install" />
            <span>
              <NavLink
                className="link-underline"
                to={`/lk/task/actions/${taskActionId}/kb_install/result`}
              >
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].assetId}
              </NavLink>
            </span>
          </>
        )}
      {status === 'success' && type === 'kb_install' && !assets && (
        <>
          <TaskType title type="kb_install" />
          <span>
            <NavLink className="link-underline" to={`/lk/task/actions/${taskActionId}/result`}>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} {assetId}
            </NavLink>
          </span>
        </>
      )}
      {status === 'success' && type === 'audit' && auditId && !assets && (
        <>
          <TaskType title type="audit" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} {assetId}
          </span>
        </>
      )}
      {status === 'success' && type === 'audit_image' && auditId && assets && (
        <>
          <TaskType title type="audit_image" />{' '}
          {assets &&
            assets[0] &&
            assets[0].type === 'image' &&
            assets[0].imageNames &&
            assets[0].imageNames.length > 0 && (
              <NavLink className="link-underline" to={`/lk/task/actions/${auditId}/audit`}>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                <span>{assets[0].imageNames[0]}</span>
              </NavLink>
            )}
        </>
      )}
      {status === 'success' && type === 'audit_image' && auditId && dataItem.registry && !assets && (
        <>
          <TaskType title type="audit_image" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.registry.name}
          </span>
        </>
      )}
      {status === 'success' && type === 'audit_image' && auditId && !dataItem.registry && !assets && (
        <>
          <TaskType title type="audit_image" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} {assetId}
          </span>
        </>
      )}
      {status === 'success' &&
        type === 'inventorization' &&
        inventorizationId &&
        assets &&
        assets[0] &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="inventorization" />{' '}
            <NavLink
              className="link-underline"
              to={`/lk/task/actions/${inventorizationId}/inventorization`}
            >
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname}
              </span>
            </NavLink>
          </>
        )}
      {status === 'success' &&
        type === 'inventorization' &&
        inventorizationId &&
        assets &&
        assets[0] &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="inventorization" />{' '}
            <NavLink
              className="link-underline"
              to={`/lk/task/actions/${inventorizationId}/inventorization`}
            >
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname}
              </span>
            </NavLink>
          </>
        )}
      {dataItem.status === 'success' &&
        dataItem.type === 'inventorization_container_registry' &&
        dataItem.registry && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {dataItem.registry.name}
            </span>
          </>
        )}
      {status === 'success' &&
        type === 'audit' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization === null && (
          <>
            <TaskType title type="audit" />{' '}
            <NavLink className="link-underline" to={`/lk/task/actions/${auditId}/audit`}>
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assetId}
              </span>
            </NavLink>
          </>
        )}
      {status === 'success' && type === 'notification' && (
        <>
          <TaskType title type="notification" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} </span>
          {dataItem.recipientEmails &&
            dataItem.recipientEmails.length > 0 &&
            dataItem.recipientEmails.map((e: string, i: number) => {
              return (
                <span key={nanoid(8)}>
                  {i >= 1 && ', '}
                  {e}
                </span>
              );
            })}
        </>
      )}
      {status === 'success' && type === 'bulletin_specific_analysis' && (
        <>
          <TaskType title type="bulletin_specific_analysis" />{' '}
          <span>
            {localeTask[currentLocale].form.bulletinSpecific[type as keyof IBulletinSpecific]}
            {dataItem.bulletinId}
          </span>
        </>
      )}
      {dataItem.status === 'success' && dataItem.type === 'asset_ldap_synchronize' && (
        <>
          <TaskType title type="asset_ldap_synchronize" />{' '}
          {/* <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.ldap.name}
          </span> */}
          <NavLink className="link-underline" to={`/lk/task/actions/${taskExecutionId}/result`}>
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {dataItem.ldap.name}
            </span>
          </NavLink>
        </>
      )}
    </>
  );
}
