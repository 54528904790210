import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter } from '@progress/kendo-react-data-tools';
import type { IDataHooks } from '../../../utils/helpers/types';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { INITIAL_CREATED_AT_SORT } from '../../../utils/helpers/constants';
import localeUser from '../../../utils/i18n/userLocales/user.json';
import { useServerList } from '../../../hooks/components/useServerList';
import { Input } from '../../common/baseElements/Input';
import { FILTER_SERVER_LIST } from '../../../utils/helpers/constant-serv-filter-host';
import { useServerContext } from '../../../hooks/useServerContext';
import { AddServerForm } from './modals/AddServerForm';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import styles from './AccountList.module.scss';
import { CommonServerList } from '../../common/CommonServerList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function ServerList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlList('ServerList', INITIAL_CREATED_AT_SORT);

  const { isServer, setServer, editServer } = useServerContext();

  const dataForHooks: IDataHooks = {
    name: 'url',
    componentName: 'ServerList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
  } = useServerList(urlParams, dataForHooks);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="server-list">
        <div className={styles.accountActions}>
          {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
            <Button
              fill="action"
              onClick={(): void => {
                setServer(true);
              }}
            >
              {localeUser[currentLocale].connectServers.addBtn}
            </Button>
          )}
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_SERVER_LIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  className="k-input custom-search"
                  name="customSearch"
                  placeholder={localeUser[currentLocale].connectServers.customSearchSrv}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonServerList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
        {(isServer || editServer) && <AddServerForm />}
      </div>
    </ErrorBoundary>
  );
}
