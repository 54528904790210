import { NavLink } from 'react-router-dom';
import { nanoid } from 'nanoid';
import type {
  IActionsValues,
  IBulletinSpecific,
  ICellActions,
} from '../../../../../utils/helpers/types';
import localeTask from '../../../../../utils/i18n/taskLocale/task.json';
import { TaskType } from '../../common/TaskType';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function CellActionFail({
  assets,
  assetId,
  type,
  status,
  dataItem,
  taskActionId,
  checkErrorInfo,
}: ICellActions): React.ReactElement {
  return (
    <>
      {status === 'fail' &&
        type === 'audit' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
              {assets[0].latestInventorization?.hostname}
            </NavLink>
          </>
        )}
      {status === 'fail' &&
        type === 'audit' &&
        assets &&
        assets[0].type === 'host' &&
        !assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
              {assets[0].assetId}
            </NavLink>
          </>
        )}
      {status === 'fail' && type === 'audit' && assets && assets[0].type === 'image' && (
        <>
          <TaskType title type="audit" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} </span>
          <NavLink className="link-underline" to={`/lk/assets/images/${assetId}`}>
            {assets[0].imageNames[0]}
          </NavLink>
        </>
      )}
      {status === 'fail' &&
        type === 'package_update' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization &&
        checkErrorInfo && (
          <>
            <TaskType title type="package_update" />
            <NavLink
              className="link-underline"
              to={`/lk/task/actions/${taskActionId}/package_update/result`}
            >
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname}
              </span>
            </NavLink>
          </>
        )}
      {status === 'fail' &&
        type === 'package_update' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization &&
        !checkErrorInfo && (
          <>
            <TaskType title type="package_update" />
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'fail' &&
        type === 'package_update' &&
        assets &&
        (assets[0].subType === 'network_device' || assets[0].type === 'image') &&
        !checkErrorInfo && (
          <>
            <TaskType title type="package_update" />
            {assets[0].subType === 'network_device' && (
              <>
                <span>
                  {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                </span>
                <span>{assets[0].latestInventorization?.hostname}</span>
              </>
            )}
            {assets[0].type === 'image' && (
              <>
                <span>
                  {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                </span>
                <span>{assets[0].imageNames[0]}</span>
              </>
            )}
          </>
        )}
      {status === 'fail' && type === 'package_update' && !assets && (
        <>
          <TaskType title type="package_update" />
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
              {assetId}
            </NavLink>
          </span>
        </>
      )}
      {status === 'fail' &&
        type === 'kb_install' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization &&
        checkErrorInfo && (
          <>
            <TaskType title type="kb_install" />
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'fail' &&
        type === 'kb_install' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization &&
        !checkErrorInfo && (
          <>
            <TaskType title type="kb_install" />
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'fail' && type === 'kb_install' && !assets && (
        <>
          <TaskType title type="kb_install" />
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} {assetId}
            </NavLink>
          </span>
        </>
      )}
      {status === 'fail' && type === 'audit_image' && (
        <>
          <TaskType title type="audit_image" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {assets &&
              assets[0].type === 'image' &&
              assets[0].imageNames &&
              assets[0].imageNames.length > 0 &&
              assets[0].imageNames[0]}
          </span>
        </>
      )}
      {status === 'fail' && type === 'inventorization' && !assets && (
        <>
          <TaskType title type="inventorization" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} </span>
        </>
      )}
      {status === 'fail' &&
        type === 'inventorization' &&
        assets &&
        assets[0].type === 'host' &&
        !assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
              {assets[0].assetId}
            </NavLink>
          </>
        )}
      {status === 'fail' &&
        type === 'inventorization' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets[0].latestInventorization?.hostname && (
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            )}
          </>
        )}
      {status === 'fail' && type === 'inventorization' && assets && assets[0].type === 'image' && (
        <>
          <TaskType title type="inventorization" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} </span>
          {assets[0].imageNames && assets[0].imageNames[0] && (
            <NavLink className="link-underline" to={`/lk/assets/images/${assetId}`}>
              {assets[0].imageNames[0]}
            </NavLink>
          )}
        </>
      )}
      {status === 'fail' &&
        type === 'inventorization' &&
        assets &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets[0].latestInventorization?.hostname ? (
              <NavLink
                className="link-underline"
                to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
              >
                {assets[0].latestInventorization.hostname}
              </NavLink>
            ) : (
              <span>{assets[0].latestInventorization.ip}</span>
            )}
          </>
        )}
      {status === 'fail' &&
        type === 'audit' &&
        assets &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets[0].latestInventorization?.hostname ? (
              <NavLink
                className="link-underline"
                to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
              >
                {assets[0].latestInventorization.hostname}
              </NavLink>
            ) : (
              <span>{assets[0].latestInventorization.ip}</span>
            )}
          </>
        )}
      {dataItem.status === 'fail' && dataItem.type === 'inventorization_container_registry' && (
        <>
          <TaskType title type="inventorization" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.registry.name}{' '}
          </span>
        </>
      )}
      {dataItem.status === 'fail' && dataItem.type === 'asset_ldap_synchronize' && (
        <>
          <TaskType title type="asset_ldap_synchronize" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.ldap.name}
          </span>
        </>
      )}
      {status === 'fail' && type === 'notification' && (
        <>
          <TaskType title type="notification" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} </span>
          {dataItem.recipientEmails &&
            dataItem.recipientEmails.length > 0 &&
            dataItem.recipientEmails.map((e: string) => {
              return <span key={nanoid(8)}>{e}</span>;
            })}
        </>
      )}
      {status === 'fail' && type === 'bulletin_specific_analysis' && (
        <>
          <TaskType title type="bulletin_specific_analysis" />{' '}
          <span>
            {localeTask[currentLocale].form.bulletinSpecific[type as keyof IBulletinSpecific]}
            {dataItem.bulletinId}
          </span>
        </>
      )}
      {status === 'fail' &&
        type === 'audit' &&
        assets &&
        assets[0].type === 'hypervisor' &&
        !assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink
              className="link-underline"
              to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
            >
              {assetId}
            </NavLink>
          </>
        )}
      {status === 'fail' &&
        type === 'audit' &&
        assets &&
        assets[0].type === 'hypervisor' &&
        assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink
              className="link-underline"
              to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
            >
              {assets[0].latestInventorization?.hostname}
            </NavLink>
          </>
        )}
      {status === 'fail' &&
        type === 'inventorization' &&
        assets &&
        assets[0].type === 'hypervisor' &&
        !assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink
              className="link-underline"
              to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
            >
              {assetId}
            </NavLink>
          </>
        )}
      {status === 'fail' &&
        type === 'inventorization' &&
        assets &&
        assets[0].type === 'hypervisor' &&
        assets[0].latestInventorization?.hostname && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            <NavLink
              className="link-underline"
              to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
            >
              {assets[0].latestInventorization?.hostname}
            </NavLink>
          </>
        )}
    </>
  );
}
