import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useServerContext } from '../../../hooks/useServerContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function ServersMutableBlock(): React.ReactElement {
  const { setOpenSrvMutableBlock, serverForm, deleteServerFromTask } = useServerContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{localeNewTask[currentLocale].form.applyForServer.title}</span>
        <Button onClick={(): void => setOpenSrvMutableBlock(true)} type="button">
          {localeNewTask[currentLocale].form.addServer}
        </Button>
      </div>
      <div className="list-assets">
        {serverForm?.map((s) => {
          if (s.name || s.ldapId) {
            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteServerFromTask(s.ldapId!, e)}
                key={nanoid()}
              >
                {s.name ? <span>{s.name}</span> : <span>{s.ldapId}</span>}
              </Button>
            );
          }

          return null;
        })}
        {(serverForm?.length === 0 || serverForm === null || !serverForm.find((s) => s.ldapId)) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForAsset.serverListPlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
