/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton } from '@progress/kendo-react-inputs';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import styles from './NewTask.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function SelectSyncSourceTask(): React.ReactElement {
  const { sourceSync, changeSourceOfSync } = useTaskAddContext();

  return (
    <div className="newtask__time">
      <label className="k-label">{localeNewTask[currentLocale].form.synchronize.titleSource}</label>
      <div className={styles.radioWrap}>
        <RadioButton
          name="synchronize"
          value="ad"
          checked={sourceSync === 'ad'}
          label={localeNewTask[currentLocale].form.synchronize.sourseDC}
          onChange={changeSourceOfSync}
        />
      </div>
      {/* <div className="newtask__datepicker">
        <div className={styles.radioWrap}>
          <RadioButton
            name="synchronize"
            value="file"
            checked={sourceSync === 'file'}
            label={localeNewTask[currentLocale].form.synchronize.sourseIsFile}
            onChange={changeSourceOfSync}
          />
        </div>
      </div> */}
    </div>
  );
}
