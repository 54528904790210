/* eslint-disable react/jsx-props-no-spreading */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  CompositeFilterDescriptor,
  State,
  process as gridProcess,
} from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Filter } from '@progress/kendo-react-data-tools';
import { useServerContext } from '../../../../hooks/useServerContext';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../utils/helpers/constants';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { StandartCell } from '../../../common/baseElements/StandartCell';
import { WithoutGrCell } from './cell/WithoutGrCell';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../utils/helpers/local-filter-helpers';
import { OPTIONS_SYNC_RESULT } from '../../../../utils/helpers/constant-local-filter-task';
import { Input } from '../../../common/baseElements/Input';
import { Button } from '../../../common/baseElements/Button';
import { useLocalExcelExport } from '../../../../hooks/components/useLocalExcelExport';
import { LdapGrCell } from './cell/LdapGrCell';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import type { TypeLdapEqualAssetGroup } from '../../../../utils/helpers/types';
import { AssetGrCell } from './cell/AssetGrCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;
export function ImportOptionsSyncTask({
  dataExecutionInfo,
  typeOfServer,
  resultLdapEqualAssetGroup,
}: {
  dataExecutionInfo: TaskExecutionDashboardResponse | undefined;
  typeOfServer: 'ldap' | 'activedirectory' | undefined;
  resultLdapEqualAssetGroup: TypeLdapEqualAssetGroup[] | null;
}): React.ReactElement {
  const { ldapGrPolicy, setLdapGrPolicy, quantifyImportOptionsSync, setServerForm } =
    useServerContext();

  const { setTaskSource, setCommonCredInTask, setDuplicate } = useTaskAddContext();

  const { setOpen } = useHostModalContext();

  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'hostname', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.VulnsSoft ? Number(pageLimitGrid.VulnsSoft) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'ldapGroupName';

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [customSearch, setCustomSearch] = useState('');

  const [dataState, setDataState] = useState<State>(initialDataState);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();

  const excelExport = useLocalExcelExport(dataState, _export, ldapGrPolicy, setDataState);

  useEffect(() => {
    if (!ldapGrPolicy || ldapGrPolicy.length === 0) {
      quantifyImportOptionsSync(dataExecutionInfo, resultLdapEqualAssetGroup);
    }
  }, []);

  useEffect(() => {
    return () => {
      setTaskSource('');
      setServerForm(null);
      setLdapGrPolicy(null);
      setCommonCredInTask(null);
      setDuplicate('doNotCheck');
      setOpen(false);
    };
  }, []);

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={(event): void =>
                onFilterChange(
                  event,
                  setCustomSearch,
                  setDataState,
                  dataState,
                  setFilter,
                  filterValue,
                  customSearch,
                  fieldFindInTable,
                )
              }
              fields={OPTIONS_SYNC_RESULT[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={
                  typeOfServer === 'ldap'
                    ? localeNewTask[currentLocale].taskSyncDescription.tabs.optionsPlaceHolderLdap
                    : localeNewTask[currentLocale].taskSyncDescription.tabs.optionsPlaceHolderAd
                }
                value={customSearch}
                onChange={(e): void =>
                  handlerCustomSearch(
                    e,
                    setCustomSearch,
                    setDataState,
                    filterValue,
                    dataState,
                    fieldFindInTable,
                  )
                }
              />
            )}
            <Button excel fill="flat" onClick={excelExport}>
              {localeNewTask[currentLocale].taskSyncDescription.tabs.exportToExcel}
            </Button>
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <ExcelExport data={ldapGrPolicy || []} ref={_export} fileName="????">
            <Grid
              pageable={ldapGrPolicy && ldapGrPolicy.length > 10 ? PAGEABLE_DATA_LOCAL : false}
              sortable
              data={gridProcess(ldapGrPolicy || [], dataState)}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent): void => {
                setDataState(e.dataState);
                if (pageLimitGrid) {
                  const pageLimit = {
                    ...pageLimitGrid,
                    VulnerabilitiesList: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                } else {
                  const pageLimit = {
                    VulnerabilitiesList: String(e.dataState.take),
                  };
                  localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                }
              }}
              className="taskaudit__vulnsgrid"
              scrollable="none"
              ref={_grid.current}
            >
              <Column
                field="ldapGroupName"
                title={
                  typeOfServer === 'ldap'
                    ? localeNewTask[currentLocale].form.ldapTable.grid.ldapGr
                    : localeNewTask[currentLocale].form.ldapTable.grid.adGr
                }
                className="title"
                filter="boolean"
              />
              <Column
                field="newGr"
                title={localeNewTask[currentLocale].form.ldapTable.grid.newGr}
                className="title"
                cell={AssetGrCell}
              />
              <Column
                field="assetGr.name"
                title={localeNewTask[currentLocale].form.ldapTable.grid.assetGr}
                className="title"
                cell={LdapGrCell}
              />
              <Column
                field=""
                title={localeNewTask[currentLocale].form.ldapTable.grid.withoutGr}
                className="title"
                cell={WithoutGrCell}
              />
            </Grid>
          </ExcelExport>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
}
