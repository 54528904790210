import { UseQueryResult, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useRef } from 'react';
import type { MenuSelectEvent } from '@progress/kendo-react-layout';
import type { AssetHostDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAssetDashboard } from '../../services/dashboard-service';
import type { AuditResponse } from '../../types/__generated/on-premise-solution/api/auditsResponse.v1';
import { infoAudit } from '../../services/audit-service';
import { useAuditModalContext } from '../useAuditModalContext';
import type { InventorizationResponse } from '../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { getTaskInventorizationId } from '../../services/task-service';
import { useCommonContext } from '../useCommonContext';
import type { Account } from '../../types/__generated/on-premise-solution/api/account.v1';
import { useCredentialContext } from '../useCredentialContext';
import { useHostActions } from './useHostActions';
import { useHostModalContext } from '../useHostModalContext';

interface IUseHostDescription {
  assetIdData: AssetHostDashboardResponse | undefined;
  assetIdIsLoading: boolean;
  hostname: string | null | undefined;
  auditIdData: AuditResponse | undefined;
  auditIdLoading: boolean;
  inventorizationIdData: InventorizationResponse | undefined;
  inventorizationIdIsLoading: boolean;
  queryDashboardAssetId: UseQueryResult<AssetHostDashboardResponse, ApiError>;
  accountOfHost: Account | undefined;
  assetId: string;
  onCredentialChange: (e: MenuSelectEvent) => void;
}
export function useHostDescription(): IUseHostDescription {
  const { assetId } = useParams<{ assetId: string }>();

  const { setAuditData } = useAuditModalContext();

  const hostname = useRef<string | undefined | null>(undefined);

  const accountOfHost = useRef<Account | undefined>(undefined);

  const { setAccountOfAsset } = useCommonContext();

  const { handleEdit, setEdit } = useCredentialContext();

  const { selectCredential, handlePort, handleDelCredential } = useHostActions();

  const { sshPort, winrmPort, smbPort } = useHostModalContext();

  const queryDashboardAssetId = useQuery<AssetHostDashboardResponse, ApiError>(
    ['dashboardAssetId', assetId],
    () => getAssetDashboard(assetId),
    {
      onSuccess: (resp) => {
        hostname.current =
          resp.type === 'host' ? resp.latestInventorization?.hostname || undefined : undefined;
        setAccountOfAsset(resp.account);
      },
      enabled: document.location.pathname.includes('/assets/hosts/'),
    },
  );

  const queryAuditId = useQuery<AuditResponse, ApiError>(
    [
      'infoAuditId',
      queryDashboardAssetId.data?.type === 'host' &&
        queryDashboardAssetId.data?.latestAudit?.auditId,
    ],
    () =>
      infoAudit(
        queryDashboardAssetId.data?.type === 'host'
          ? queryDashboardAssetId.data?.latestAudit?.auditId
          : undefined,
      ),
    {
      onSuccess: (data) => {
        setAuditData(data);
      },
      keepPreviousData: true,
      enabled: !!(
        queryDashboardAssetId.data?.type === 'host' &&
        queryDashboardAssetId.data?.latestAudit?.auditId
      ),
    },
  );

  const queryInventorizationId = useQuery<InventorizationResponse, ApiError>(
    [
      'inventorizationId',
      queryDashboardAssetId.data?.type === 'host' &&
        queryDashboardAssetId.data?.latestInventorization?.inventorizationId,
    ],
    () =>
      getTaskInventorizationId(
        queryDashboardAssetId.data?.type === 'host'
          ? queryDashboardAssetId.data?.latestInventorization?.inventorizationId
          : undefined,
      ),
    {
      keepPreviousData: true,
      enabled: !!(
        queryDashboardAssetId.data?.type === 'host' &&
        queryDashboardAssetId.data?.latestInventorization?.inventorizationId
      ),
    },
  );

  const onCredentialChange = (e: MenuSelectEvent): void => {
    const actionType:
      | 'edit'
      | 'changeCredential'
      | 'changeSsh'
      | 'changeSmbPort'
      | 'changeWinRmPort'
      | 'delete' = e.item.data;

    switch (actionType) {
      case 'edit':
        handleEdit(queryDashboardAssetId.data?.credential?.credentialId);
        setEdit(true);
        break;

      case 'changeCredential':
        selectCredential({
          assetId: queryDashboardAssetId.data?.assetId,
          accountId: queryDashboardAssetId.data?.accountId,
          assetGroupIds: queryDashboardAssetId.data?.assetGroupIds,
          credentialId: queryDashboardAssetId.data?.credential?.credentialId,
          osType:
            queryDashboardAssetId.data?.type === 'host'
              ? queryDashboardAssetId.data.latestInventorization?.os.type
              : undefined,
          assetType: queryDashboardAssetId.data?.type,
        });
        break;

      case 'changeSsh':
        handlePort({
          assetId: queryDashboardAssetId.data?.assetId,
          accountId: queryDashboardAssetId.data?.accountId,
          assetGroupIds: queryDashboardAssetId.data?.assetGroupIds,
          credentialId: queryDashboardAssetId.data?.credential?.credentialId,
          osType:
            queryDashboardAssetId.data?.type === 'host'
              ? queryDashboardAssetId.data.latestInventorization?.os.type
              : undefined,
          assetType: queryDashboardAssetId.data?.type,
          sshPort: Number(sshPort),
          isSsh: true,
        });
        break;

      case 'changeSmbPort':
        handlePort({
          assetId: queryDashboardAssetId.data?.assetId,
          accountId: queryDashboardAssetId.data?.accountId,
          assetGroupIds: queryDashboardAssetId.data?.assetGroupIds,
          credentialId: queryDashboardAssetId.data?.credential?.credentialId,
          osType:
            queryDashboardAssetId.data?.type === 'host'
              ? queryDashboardAssetId.data?.latestInventorization?.os.type
              : undefined,
          assetType: queryDashboardAssetId.data?.type,
          smbPort: Number(smbPort),
          isSmbPort: true,
        });
        break;

      case 'changeWinRmPort':
        handlePort({
          assetId: queryDashboardAssetId.data?.assetId,
          accountId: queryDashboardAssetId.data?.accountId,
          assetGroupIds: queryDashboardAssetId.data?.assetGroupIds,
          credentialId: queryDashboardAssetId.data?.credential?.credentialId,
          osType:
            queryDashboardAssetId.data?.type === 'host'
              ? queryDashboardAssetId.data?.latestInventorization?.os.type
              : undefined,
          assetType: queryDashboardAssetId.data?.type,
          winrmPort: Number(winrmPort),
          isWinrmPort: true,
        });
        break;

      case 'delete':
        if (queryDashboardAssetId.data)
          handleDelCredential(queryDashboardAssetId.data.assetId, queryDashboardAssetId.data);
        break;

      default:
        break;
    }
  };

  return {
    assetIdData: queryDashboardAssetId.data,
    assetIdIsLoading: queryDashboardAssetId.isLoading,
    hostname: hostname.current,
    auditIdData: queryAuditId.data,
    auditIdLoading: queryAuditId.isLoading,
    inventorizationIdData: queryInventorizationId.data,
    inventorizationIdIsLoading: queryInventorizationId.isLoading,
    queryDashboardAssetId,
    accountOfHost: accountOfHost.current,
    assetId,
    onCredentialChange,
  };
}
