import { nanoid } from 'nanoid';
import { NavLink } from 'react-router-dom';
import type {
  IActionsValues,
  IBulletinSpecific,
  ICellActions,
} from '../../../../../utils/helpers/types';
import localeTask from '../../../../../utils/i18n/taskLocale/task.json';
import { TaskType } from '../../common/TaskType';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function CellActionProcessing({
  assets,
  type,
  status,
  auditId,
  inventorizationId,
  dataItem,
  taskActionId,
  assetId,
}: ICellActions): React.ReactElement {
  return (
    <>
      {(status === 'processing' &&
        type === 'audit' &&
        !auditId &&
        assets &&
        assets[0].latestInventorization !== null) ||
        (status === 'success' && type === 'audit' && !auditId && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {assets && assets[0].type === 'host' && assets[0].latestInventorization?.hostname}
            </span>
          </>
        ))}
      {status === 'processing' && type === 'audit_image' && (
        <>
          <TaskType title type="audit_image" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {assets &&
              assets[0].type === 'image' &&
              assets[0].imageNames &&
              assets[0].imageNames.length > 0 &&
              assets[0].imageNames[0]}
          </span>
        </>
      )}
      {status === 'processing' &&
        type === 'package_update' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="package_update" />
            <span>
              {/* {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink> */}
              <NavLink
                className="link-underline"
                to={`/lk/task/actions/${taskActionId}/package_update/result`}
              >
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'processing' &&
        type === 'kb_install' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization && (
          <>
            <TaskType title type="kb_install" />
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <span>{assets[0].latestInventorization?.hostname}</span>
            </span>
          </>
        )}
      {status === 'processing' &&
        type === 'inventorization' &&
        !inventorizationId &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {assets[0].latestInventorization?.hostname
                ? assets[0].latestInventorization?.hostname
                : assets[0].assetId}
            </span>
          </>
        )}
      {status === 'processing' &&
        type === 'inventorization' &&
        !inventorizationId &&
        assets &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {assets[0].latestInventorization?.hostname
                ? assets[0].latestInventorization?.hostname
                : assets[0].assetId}
            </span>
          </>
        )}
      {dataItem.status === 'processing' && dataItem.type === 'inventorization_container_registry' && (
        <>
          <TaskType title type="inventorization" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.registry.name}
          </span>
        </>
      )}
      {status === 'processing' && type === 'audit' && assets && (
        <>
          <TaskType title type="audit" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {assets &&
              assets[0].type === 'host' &&
              (assets[0].latestInventorization?.hostname || assetId)}
          </span>
        </>
      )}
      {status === 'processing' && type === 'notification' && (
        <>
          <TaskType title type="notification" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}</span>
          {dataItem.recipientEmails &&
            dataItem.recipientEmails.length > 0 &&
            dataItem.recipientEmails.map((e: string, i: number) => {
              return (
                <span key={nanoid(8)}>
                  {i >= 1 && ', '}
                  {e}
                </span>
              );
            })}
        </>
      )}
      {status === 'processing' && type === 'bulletin_specific_analysis' && (
        <>
          <TaskType title type="bulletin_specific_analysis" />{' '}
          <span>
            {localeTask[currentLocale].form.bulletinSpecific[type as keyof IBulletinSpecific]}
            {dataItem.bulletinId}
          </span>
        </>
      )}
      {dataItem.status === 'processing' && dataItem.type === 'asset_ldap_synchronize' && (
        <>
          <TaskType title type="asset_ldap_synchronize" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.ldap.name}
          </span>
        </>
      )}
    </>
  );
}
