import { nanoid } from 'nanoid';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useServerContext } from '../../../../hooks/useServerContext';
import styles from './NewTask.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function PolicyLdapBlockTask(): React.ReactElement {
  const { ldapGrForm, serverForm } = useServerContext();

  return (
    <div className={styles.policyLdapBlockTask}>
      <table className={`${styles.formCredentialTable} horizontal`}>
        <thead>
          <tr>
            <th style={{ width: '120px' }}>
              {(serverForm && serverForm[0].type === 'ldap') ||
              (ldapGrForm && ldapGrForm[0].type === 'ldap')
                ? localeNewTask[currentLocale].form.ldapTable.grid.ldapGrName
                : localeNewTask[currentLocale].form.ldapTable.grid.adGrName}
            </th>
            <th style={{ width: '120px' }}>
              {localeNewTask[currentLocale].form.ldapTable.grid.createGr}
            </th>
            <th style={{ width: '110px' }}>
              {localeNewTask[currentLocale].form.ldapTable.grid.selectGr}
            </th>
            <th style={{ width: '68px' }}>
              {localeNewTask[currentLocale].form.ldapTable.grid.withoutGr}
            </th>
          </tr>
        </thead>
        <tbody>
          {ldapGrForm?.map((l) => (
            <tr key={nanoid()}>
              <td title={l.ldapGroupName ? l.ldapGroupName : ''}>{l.ldapGroupName}</td>
              <td title={l.newGr ? l.newGr : ''}>
                {l.newGr ? l.newGr : <span className="k-icon k-font-icon k-i-minus" />}
              </td>
              <td title={l.assetGr ? l.assetGr.name : ''}>
                {l.assetGr ? l.assetGr.name : <span className="k-icon k-font-icon k-i-minus" />}
              </td>
              <td>
                {!l.newGr && !l.assetGr ? (
                  <span className="k-icon k-font-icon k-i-plus" />
                ) : (
                  <span className="k-icon k-font-icon k-i-minus" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
