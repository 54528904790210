import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeUser from '../../../../utils/i18n/userLocales/user.json';
import { useServerContext } from '../../../../hooks/useServerContext';
import { getUserData } from '../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function CellSrvListMenu({ dataItem }: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { mutationLdapDel, handleDelete, handleEditServer } = useServerContext();

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="auth-server"
          name={dataItem?.name}
          onClose={(): void => setConfirm(false)}
          handleDelete={(): void => handleDelete(dataItem.ldapId, setConfirm)}
          errorMessage={mutationLdapDel.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => handleEditServer(dataItem)}
                disabled={currentUser?.role === 'user' || currentUser?.role === 'reader_user'}
              >
                {localeUser[currentLocale].connectServers.editmenu}
              </Button>
              <Button
                fill="menu"
                onClick={handleConfirm}
                disabled={currentUser?.role === 'user' || currentUser?.role === 'reader_user'}
              >
                {localeUser[currentLocale].connectServers.deleteMenu}
              </Button>
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
