/* eslint-disable react/jsx-pascal-case */
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useQuery } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useRef } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import '../TaskDescription.module.scss';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { useRepeatTask } from '../../../../hooks/components/useRepeatTask';
import { MetaBlock } from './MetaBlock';
import type { EnrichedTaskActionsResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionsResponse.v1';
import { getResultTaskActionSync, getTaskExecutionInfo } from '../../../../services/task-service';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { TabsSyncDetails } from './TabsSyncDetails';
import type { TCredentialIdTaskSync } from '../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function TaskSyncDetails(): React.ReactElement {
  const ldapName = useRef<string | undefined>();

  const assetDuplicationsSearchPolicy = useRef<
    'globally' | 'inside_asset_group' | 'ignore' | undefined
  >(undefined);

  const bindCredentials = useRef<TCredentialIdTaskSync[] | undefined>(undefined);

  const { taskExecutionId } = useParams<{ taskExecutionId: string }>();

  const { repeatTaskDescription } = useRepeatTask();

  const query = useQuery<EnrichedTaskActionsResponse, ApiError>(
    ['taskActionSync', taskExecutionId],
    () => getResultTaskActionSync(taskExecutionId),
    {
      onSuccess: (resp) => {
        if (resp) {
          ldapName.current = resp.data[0].ldap?.name;
        }
      },
      keepPreviousData: true,
    },
  );

  const queryExecutionInfo = useQuery<TaskExecutionDashboardResponse, ApiError>(
    ['taskExecutionId', taskExecutionId],
    () => getTaskExecutionInfo(taskExecutionId),
    {
      keepPreviousData: true,
      onSuccess: (resp) => {
        if (resp.taskType === 'asset_synchronize') {
          assetDuplicationsSearchPolicy.current =
            resp.actions.asset_ldap_synchronize?.assetDuplicationsSearchPolicy;
          bindCredentials.current = resp.actions.asset_ldap_synchronize?.bindCredentials;
        }
      },
    },
  );

  return (
    <div>
      <div className="taskdescription">
        <div className="common-header-page">
          <Typography.h3 className="title">
            <span className="audit-taskname">
              {localeNewTask[currentLocale].taskSyncDescription.title} {ldapName.current}
            </span>
          </Typography.h3>
          <div className="topactions">
            <Button onClick={(): void => repeatTaskDescription(queryExecutionInfo.data, undefined)}>
              {localeNewTask[currentLocale].taskSyncDescription.btns.repeat}
            </Button>
          </div>
        </div>
        <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
          <MetaBlock
            data={query.data}
            queryExecutionInfo={queryExecutionInfo}
            assetDuplicationsSearchPolicy={assetDuplicationsSearchPolicy}
            bindCredentials={bindCredentials}
          />
          <TabsSyncDetails data={query.data?.data} dataExecutionInfo={queryExecutionInfo.data} />
        </ErrorBoundary>
      </div>
    </div>
  );
}
