/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useHostModalContext } from '../../../hooks/useHostModalContext';

export function CellSelectGroups(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { hostForm, hostFormTemp, setHostFormTemp } = useHostModalContext();

  useEffect(() => {
    if (!hostFormTemp) {
      setHostFormTemp(hostForm);
    }
  }, []);

  const addAssetGroup = useCallback(
    ({ name, assetGroupId }: { name: string; assetGroupId: string }): void => {
      if (hostFormTemp && hostFormTemp.length > 0) {
        const index = hostFormTemp?.findIndex((a) => a.assetGroupId === assetGroupId);
        if (index !== -1) {
          const cutHostForm = hostFormTemp.filter((host) => host.assetGroupId !== assetGroupId);
          setHostFormTemp([...cutHostForm]);
        } else {
          setHostFormTemp([
            ...hostFormTemp,
            {
              assetGroupId,
              name,
            },
          ]);
        }
      } else {
        setHostFormTemp([
          {
            assetGroupId: dataItem.assetGroupId,
            name: dataItem.name,
          },
        ]);
      }
    },
    [hostFormTemp, setHostFormTemp, dataItem.assetGroupId, dataItem.name],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={!!hostFormTemp?.find((a) => a.assetGroupId === dataItem.assetGroupId)}
        onClick={(): void =>
          addAssetGroup({
            name: dataItem.name,
            assetGroupId: dataItem.assetGroupId,
          })
        }
      />
    </td>
  );
}
