import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useServerContext } from '../../../hooks/useServerContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import type { TLdapGrFormValues } from '../../../utils/helpers/types';

export function CellSelectGroup(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { ldapGrForm, setLdapGrForm, currentCommonName } = useServerContext();

  const { hostFormTemp, setHostFormTemp } = useHostModalContext();

  const addAssetGroup = useCallback((): void => {
    if (currentCommonName) {
      const MutabledLdapGrForm = ldapGrForm?.map((g) => {
        if (g.ldapGroupName === currentCommonName) {
          return {
            ...g,
            assetGr: { name: dataItem.name, id: dataItem.assetGroupId },
            newGr: null,
            assetGroupPolicy: 'add_to_existing',
          };
        }

        return g;
      }) as TLdapGrFormValues[];

      if (MutabledLdapGrForm) setLdapGrForm(MutabledLdapGrForm);
    }
    setHostFormTemp([
      {
        assetGroupId: dataItem.assetGroupId,
        name: dataItem.name,
      },
    ]);
  }, [
    currentCommonName,
    dataItem.assetGroupId,
    dataItem.name,
    ldapGrForm,
    setHostFormTemp,
    setLdapGrForm,
  ]);

  // useEffect(() => {
  //   return () => setHostFormTemp(null);
  // }, []);

  return (
    <td style={{ textOverflow: 'clip' }}>
      <RadioButton
        checked={(hostFormTemp && hostFormTemp[0].assetGroupId === dataItem.assetGroupId) || false}
        onClick={addAssetGroup}
      />
    </td>
  );
}
