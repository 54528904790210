import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import type { LdapsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';

export function CellServerName({
  dataItem: { name },
  dataItem: { ldapId },
}: Omit<GridCellProps, 'name' | 'ldapId'> & {
  dataItem: LdapsDashboardResponse;
}): React.ReactElement {
  const { setCveName } = useAuditModalContext();

  return (
    <td title={name || ldapId}>
      <NavLink onClick={(): void => setCveName(null)} to={`/lk/users/servers/${ldapId}`}>
        {name || ldapId}
      </NavLink>
    </td>
  );
}
