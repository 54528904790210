import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { DrawerRouterContainer } from '../components/hoc/DrawerRouterContainer/DrawerRouterContainer';
import { AccountModalProvider } from '../hooks/useAccountModalContext';
import { UserModalProvider } from '../hooks/useUserModalContext';
import { HostModalProvider } from '../hooks/useHostModalContext';
import { useAuditModalContext } from '../hooks/useAuditModalContext';
import { getUserData } from '../services/local-storage-service';
import { HostCommand } from '../components/features/Host/hostCommand/HostCommand';
import { AxiosVmInterceptor } from '../lib/axios/hooks/useVmAxios';
import { AxiosDashboardInterceptor } from '../lib/axios/hooks/useDashboardAxios';
import { AxiosAuthInterceptor } from '../lib/axios/hooks/useAuthAxios';
import { logout } from '../services/auth';
import { HostCreateProvider } from '../hooks/useHostCreateContext';
import { AddTask } from '../components/features/Task/form/AddTask';
import { TaskProvider } from '../hooks/useTaskContext';
import { HeaderProvider } from '../hooks/useHeaderContext';
import { TaskVmDetails } from '../components/features/Task/taskVmDetails/TaskVmDetails';
import { TaskIDetails } from '../components/features/Task/taskIDetails/TaskIDetails';
import { Settings } from '../components/features/Settings/Settings';
import { User } from '../components/features/User/User';
import { Doc } from '../components/features/Doc/Doc';
import { GroupProvider } from '../hooks/useGroupContext';
import { DashboardInventorization } from '../components/features/Dashboard/inventorization/DashboardInventorization';
import { DashboardVulns } from '../components/features/Dashboard/audit/DashboardVulns';
import { SettingsLogs } from '../components/features/Settings/Logs/SettingsLogs';
import { VulnerabilitiesDetails } from '../components/features/Security/VulnerabilitiesDetails';
import { VectorModal } from '../components/common/modal/vulnModal/VectorModal';
import { Credentials } from '../components/features/Credentials/Credentials';
import { SystemInfoProvider } from '../hooks/useSystemInfoContext';
import { TaskAddProvider } from '../hooks/useTaskAddContext';
import { ImageRegisterTabs } from '../components/features/Containers/ImageRegisterTabs';
import { RegisterProvider } from '../hooks/useRegisterContext';
import { DeviceList } from '../components/features/Devices/DeviceList';
import { DeviceDescription } from '../components/features/Devices/deviceComponents/DeviceDescription';
import { NetDeviceProvider } from '../hooks/useNetDeviceContext';
import { ImageDescription } from '../components/features/Containers/Image/imageComponents/ImageDescription';
import { RegistryDescription } from '../components/features/Containers/Registers/registryComponents/RegistryDescription';
import { HostDescription } from '../components/features/Host/hostComponents/HostDescription';
import { HostList } from '../components/features/Host/hostList/HostList';
import { VdbList } from '../components/features/VDB/VdbList';
import { TaskHistory } from '../components/features/Task/TaskHistory';
import { TaskDescription } from '../components/features/Task/TaskDescription';
import { TaskList } from '../components/features/Task/TaskList';
import { TaskPDetails } from '../components/features/Task/taskPDetails/TaskPDetails';
import { ApiKeyProvider } from '../hooks/useApiKeyContext';
import { ApiKeyDescription } from '../components/features/Settings/ApiKeys/ApiKeyDescription';
import { GroupList } from '../components/features/Group/GroupList';
import { GroupDescription } from '../components/features/Group/groupComponents/GroupDescription';
import { ServerProvider } from '../hooks/useServerContext';
import { CredentialContextProvider } from '../hooks/useCredentialContext';
import { WhiteListProvider } from '../hooks/useWhiteListContext';
import { WhiteListDescription } from '../components/features/WhiteList/WhiteListDescription';
import { WhiteList } from '../components/features/WhiteList/WhiteList';
import { AssetCreatePropProvider } from '../hooks/useAssetCreatePropContext';
import { DifferenceModalProvider } from '../hooks/useDifferenceModalContext';
import { TaskKbDetails } from '../components/features/Task/TaskKbDetails/TaskKbDetails';
import { ImageProvider } from '../hooks/useImageContext';
import { ServerDescription } from '../components/features/User/ServerDescription';
import { TaskSyncDetails } from '../components/features/Task/taskSyncDetails/TaskSyncDetails';

export function Drawer(): React.ReactElement {
  const history = useHistory();
  const userData = getUserData();
  const helmetContext = {};
  const { isOpen, cvssVector } = useAuditModalContext();

  useEffect(() => {
    if (!userData) {
      history.push('/auth');
    }
  }, [history, userData]);

  const handleLogout = async (): Promise<void> => {
    await logout();
    history.push('/auth');
  };

  return (
    <HelmetProvider context={helmetContext}>
      <HostCreateProvider>
        <AccountModalProvider>
          <DifferenceModalProvider>
            <CredentialContextProvider>
              <UserModalProvider>
                <HostModalProvider>
                  <AssetCreatePropProvider>
                    <TaskProvider>
                      <HeaderProvider>
                        <GroupProvider>
                          <AxiosAuthInterceptor>
                            <AxiosVmInterceptor>
                              <AxiosDashboardInterceptor>
                                <RegisterProvider>
                                  <ImageProvider>
                                    <NetDeviceProvider>
                                      <SystemInfoProvider>
                                        <ApiKeyProvider>
                                          <WhiteListProvider>
                                            <ServerProvider>
                                              <BrowserRouter>
                                                <TaskAddProvider>
                                                  <DrawerRouterContainer
                                                    handleLogout={handleLogout}
                                                  >
                                                    <div className="modals">
                                                      {isOpen && cvssVector && <VectorModal />}
                                                    </div>
                                                    <Switch>
                                                      <Route
                                                        exact
                                                        path="/lk/users/list"
                                                        component={User}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/users/organization"
                                                        component={User}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/users/roles"
                                                        component={User}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/users/servers"
                                                        component={User}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/users/servers/:ldapId?"
                                                        component={ServerDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/devices"
                                                        component={DeviceList}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/hosts"
                                                        component={HostList}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/images/"
                                                        component={ImageRegisterTabs}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/registries/"
                                                        component={ImageRegisterTabs}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/groups"
                                                        component={GroupList}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/security/vdb"
                                                        component={VdbList}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/groups/:assetGroupId?"
                                                        component={GroupDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/hosts/:assetId?"
                                                        component={HostDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/images/:assetId?"
                                                        component={ImageDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/registries/:registryId?/repositories"
                                                        component={RegistryDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/registries/:registryId?/historytask"
                                                        component={RegistryDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/devices/firewall/:assetId?"
                                                        component={DeviceDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/devices/router/:assetId?"
                                                        component={DeviceDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/devices/switch/:assetId?"
                                                        component={DeviceDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/assets/devices/hypervisor/:assetId?"
                                                        component={DeviceDescription}
                                                      />
                                                      <Route
                                                        path="/lk/assets/command/:assetId?"
                                                        component={HostCommand}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/audit/tasks"
                                                        component={TaskList}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/audit/history"
                                                        component={TaskHistory}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:auditId?/audit"
                                                        component={TaskVmDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:taskExecutionId?/result"
                                                        component={TaskSyncDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:taskActionId?/package_update/result"
                                                        component={TaskPDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:taskActionId?/package_update/console"
                                                        component={TaskPDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:taskActionId?/kb_install/result"
                                                        component={TaskKbDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:taskActionId?/kb_install/logs"
                                                        component={TaskKbDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/actions/:inventorizationId?/inventorization"
                                                        component={TaskIDetails}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/task/executions/:taskExecutionId?"
                                                        component={TaskDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/dashboard/inventorization"
                                                        component={DashboardInventorization}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/dashboard/vulnerabilities"
                                                        component={DashboardVulns}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/security/vulnerabilities"
                                                        component={VulnerabilitiesDetails}
                                                      />
                                                      <Route
                                                        path="/lk/dashboard/newtask"
                                                        component={AddTask}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/license"
                                                        component={Settings}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/update"
                                                        component={Settings}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/system"
                                                        component={Settings}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/smtp"
                                                        component={Settings}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/tokens"
                                                        component={Settings}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/whitelists"
                                                        component={Settings}
                                                      />
                                                      <Route
                                                        path="/lk/credentials/list"
                                                        component={Credentials}
                                                      />
                                                      <Route
                                                        path="/lk/credentials/vaults"
                                                        component={Credentials}
                                                      />
                                                      <Route path="/lk/doc/" component={Doc} />
                                                      <Route
                                                        path="/lk/systemlog/users"
                                                        component={SettingsLogs}
                                                      />
                                                      <Route
                                                        path="/lk/systemlog/tasks"
                                                        component={SettingsLogs}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/settings/tokens/:apiKeyId?"
                                                        component={ApiKeyDescription}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/audit/exceptions"
                                                        component={WhiteList}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/lk/audit/exceptions/:bulletinWhitelistId"
                                                        component={WhiteListDescription}
                                                      />
                                                      {/* <Route
                                                  exact
                                                  path="/lk/settings/whitelists/:bulletinWhitelistId/bulletins"
                                                  component={WhiteListDescription}
                                                />
                                                <Route
                                                  exact
                                                  path="/lk/settings/whitelists/:bulletinWhitelistId/assets"
                                                  component={WhiteListDescription}
                                                />
                                                <Route
                                                  exact
                                                  path="/lk/settings/whitelists/:bulletinWhitelistId/groups"
                                                  component={WhiteListDescription}
                                                /> */}
                                                    </Switch>
                                                  </DrawerRouterContainer>
                                                </TaskAddProvider>
                                              </BrowserRouter>
                                            </ServerProvider>
                                          </WhiteListProvider>
                                        </ApiKeyProvider>
                                      </SystemInfoProvider>
                                    </NetDeviceProvider>
                                  </ImageProvider>
                                </RegisterProvider>
                              </AxiosDashboardInterceptor>
                            </AxiosVmInterceptor>
                          </AxiosAuthInterceptor>
                        </GroupProvider>
                      </HeaderProvider>
                    </TaskProvider>
                  </AssetCreatePropProvider>
                </HostModalProvider>
              </UserModalProvider>
            </CredentialContextProvider>
          </DifferenceModalProvider>
        </AccountModalProvider>
      </HostCreateProvider>
    </HelmetProvider>
  );
}
