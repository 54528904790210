import type { GridCellProps } from '@progress/kendo-react-grid';
import { IMG_SRC_VALUES } from '../../../../../utils/helpers/constants';
import style from '../../TaskList.module.scss';
import { getMainPartOfOsName } from '../../../../../utils/helpers/inventorization-helper';

export function OcCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  let osName = '';
  if (dataItem?.os?.name) {
    osName = getMainPartOfOsName(dataItem?.os?.name);
  }

  return (
    <td style={{ position: 'relative' }}>
      <div className={style.cellOsWrap}>
        {/* <img
          src={`${process.env.PUBLIC_URL}/images/os_logo/${IMG_SRC_VALUES[osName.toLowerCase()]}`}
          alt={dataItem.os.type}
        /> */}
        {dataItem.os && dataItem.os.type && dataItem.os.type[0] ? (
          <span>{`${dataItem.os.type[0].toUpperCase()}${dataItem.os.type.slice(1)}`}</span>
        ) : (
          <span>-</span>
        )}
        {/* <span className={style.cellOsType}>
          {dataItem.os &&
            dataItem.os.type &&
            `${dataItem.os.type[0].toUpperCase()}${dataItem.os.type.slice(1)}`}
        </span> */}
      </div>
    </td>
  );
}
