import { IHostForm, useHostModalContext } from '../useHostModalContext';
import { useTaskContext } from '../useTaskContext';
import type { TaskExecutionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import type {
  IDeviceForm,
  IImageForm,
  IRegistryForm,
  TCommonCredInTask,
  TLdapGrFormValues,
} from '../../utils/helpers/types';
import { useRegisterContext } from '../useRegisterContext';
import type { EnrichedTaskActionResponse } from '../../types/__generated/on-premise-solution/api/enrichedTaskActionsResponse.v1';
import type { AuditDashboardResponse } from '../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import { getAllUsers } from '../../services/user-service';
import { useUserModalContext } from '../useUserModalContext';
import type { User } from '../../types/__generated/on-premise-solution/api/user.v1';
import { useNetDeviceContext } from '../useNetDeviceContext';
import { useTaskAddContext } from '../useTaskAddContext';
import { kbRebootData, kbUpdateSrcData, reportTypeValue } from '../../utils/helpers/constants';
import { setCurrentLocale } from '../../utils/helpers/add-task-helper';
import { useServerContext } from '../useServerContext';

interface IRepeatTaskHostRegistry {
  repeatTaskDescription(
    data: TaskExecutionDashboardResponse | undefined,
    registries: IRegistryForm[] | undefined,
  ): void;
  repeatTaskDetails: (
    data: EnrichedTaskActionResponse | undefined,
    type: string,
    taskName: string,
  ) => void;
  // repeatTaskVmDetails: (
  //   data: AuditDashboardResponse | undefined,
  //   type: string,
  //   wl: string | false,
  //   taskName: string,
  // ) => void;
  repeatAuditTask: (
    data: AuditDashboardResponse | undefined,
    type: string,
    wl: string | false,
    taskName: string,
  ) => void;
}

const currentLocale = setCurrentLocale();
export function useRepeatTask(): IRepeatTaskHostRegistry {
  const { setHostForm, setSelectedPkgs, setMissedKbs } = useHostModalContext();

  const { setOpenDialog, setTaskRepeatData, setSelectedBulletinIds } = useTaskContext();

  const { setServerForm, setLdapGrForm } = useServerContext();

  const {
    setTaskSource,
    setReportType,
    setTopN,
    setHtmlFormat,
    setPdfFormat,
    handleFromTaskAction,
    handleToTaskAction,
    setKbUpdateSrcVal,
    setKbRebootVal,
    setPkgRebootVal,
    setDuplicate,
    setCommonCredInTask,
  } = useTaskAddContext();

  const { setRegistryForm, setImageForm } = useRegisterContext();

  const { setUserTaskEmail, setUserTaskInputEmail } = useUserModalContext();

  const { setDeviceForm } = useNetDeviceContext();

  const repeatTaskDescription = (
    data: TaskExecutionDashboardResponse | undefined,
    registries: IRegistryForm[] | undefined,
  ): void => {
    if (data) {
      let assetsValue = [] as IHostForm[];

      let assetGroupValue = [] as IHostForm[];

      let assetRegistryValue = [] as IRegistryForm[];

      let deviceValues = [] as IDeviceForm[];

      let imageValues = [] as IImageForm[];

      const hosts =
        (data.taskType === 'host' || data.taskType === 'report') &&
        data.scope.assets?.filter((h) => h.type === 'host');

      const images =
        data.taskType === 'image' && data.scope.assets?.filter((im) => im.type === 'image');

      const devices =
        data.taskType === 'device' &&
        data.scope.assets?.filter(
          (h) => h.type === 'firewall' || h.type === 'router' || h.type === 'switch',
        );

      if (hosts && hosts.length > 0) {
        assetsValue = hosts.map((a) => {
          return {
            assetId: a.assetId,
            hostname: 'hostname' in a ? a.hostname : null,
          };
        });
      } else if (data.taskType === 'image' && data.scope.assets?.find((a) => a.type === 'host')) {
        assetsValue = data.scope.assets.map((a) => {
          return {
            assetId: a.assetId,
            hostname: 'hostname' in a ? a.hostname : null,
          };
        });
      }

      if (images && images.length > 0) {
        imageValues = images.map((im) => {
          return {
            imageNames: 'imageNames' in im ? im.imageNames[0] : '',
            assetId: im.assetId,
          };
        });
      }

      if (devices && devices.length > 0) {
        deviceValues = devices.map((a) => {
          return {
            assetId: a.assetId,
            hostname: 'hostname' in a ? a.hostname : null,
          };
        });
      }

      if (data.taskType === 'report' && hosts && hosts.length > 0) {
        assetsValue = hosts.map((a) => {
          return {
            assetId: a.assetId,
            hostname: 'hostname' in a ? a.hostname : null,
          };
        });
      }
      if (data.taskType === 'report' && devices && devices.length > 0) {
        deviceValues = devices.map((a) => {
          return {
            assetId: a.assetId,
            hostname: 'hostname' in a ? a.hostname : null,
          };
        });
      }

      if (data?.taskType === 'host' && data?.scope.assetGroups) {
        assetGroupValue = data?.scope.assetGroups.map((a) => {
          return {
            assetGroupId: a.assetGroupId,
            name: a.name,
          };
        });
      }

      if (data.taskType === 'asset') {
        // for hosts
        if (data.scope.assets?.find((h) => h.type === 'host')) {
          const h = data.scope.assets.filter((host) => host.type === 'host');
          assetsValue = h.map((a) => {
            return {
              assetId: a.assetId,
              hostname: 'hostname' in a ? a.hostname : null,
            };
          });
        }
        // groups
        if (data.scope.assetGroups && data.scope.assetGroups.length > 0) {
          assetGroupValue = data?.scope.assetGroups.map((a) => {
            return {
              assetGroupId: a.assetGroupId,
              name: a.name,
            };
          });
        }
        // for devices
        if (
          data.scope.assets?.find(
            (device) =>
              device.type === 'firewall' || device.type === 'router' || device.type === 'switch',
          )
        ) {
          const device = data.scope.assets.filter(
            (d) => d.type === 'firewall' || d.type === 'router' || d.type === 'switch',
          );
          deviceValues = device.map((a) => {
            return {
              assetId: a.assetId,
              hostname: 'hostname' in a ? a.hostname : null,
            };
          });
        }
        // for images
        if (data.scope.assets?.find((h) => h.type === 'image')) {
          const image = data.scope.assets.filter((im) => im.type === 'image');
          imageValues = image.map((im) => {
            return {
              imageNames: 'imageNames' in im ? im.imageNames[0] : '',
              assetId: im.assetId,
            };
          });
        }

        if (data.scope.assets?.find((v) => v.type === 'hypervisor')) {
          const d = data.scope.assets?.filter((host) => host.type === 'hypervisor');
          deviceValues = d.map((a) => {
            return {
              assetId: a.assetId,
              hostname: 'hostname' in a ? a.hostname : null,
            };
          });
        }
      }

      if (data?.taskType === 'report' && data?.scope.assetGroups) {
        assetGroupValue = data?.scope.assetGroups.map((a) => {
          return {
            assetGroupId: a.assetGroupId,
            name: a.name,
          };
        });
      }

      if (registries) {
        assetRegistryValue = registries.map((r) => {
          return {
            registryId: r.registryId,
            name: r.name,
          };
        });
        setRegistryForm([...assetRegistryValue]);
      }

      if (data.taskType === 'asset' && data.scope.registries) {
        assetRegistryValue = data.scope.registries.map((r) => {
          return {
            registryId: r.registryId,
            name: r.name,
          };
        });
        setRegistryForm([...assetRegistryValue]);
      }

      if (
        assetsValue &&
        assetsValue.length > 0 &&
        (!assetGroupValue || assetGroupValue.length === 0)
      ) {
        setHostForm([...assetsValue]);
      } else if (
        assetGroupValue &&
        assetGroupValue.length > 0 &&
        (!assetsValue || assetsValue.length === 0)
      ) {
        setHostForm([...assetGroupValue]);
      } else if (
        assetsValue &&
        assetsValue.length > 0 &&
        assetGroupValue &&
        assetGroupValue.length > 0
      ) {
        setHostForm([...assetsValue, ...assetGroupValue]);
      }

      if (deviceValues && deviceValues.length > 0) {
        setDeviceForm([...deviceValues]);
      }

      if (imageValues && imageValues.length > 0) {
        setImageForm([...imageValues]);
      }

      if (data.taskType === 'report' && images && images.length > 0) {
        setTaskSource('image');
        setImageForm([...imageValues]);
      }

      // TODO: после обновления спеки добавить type: asset
      if (
        (data?.taskType === 'host' || data?.taskType === 'asset') &&
        !data.actions.package_update &&
        !data.actions.kb_install
      ) {
        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'asset',
          actionA: data?.actions.audit ? 'audit' : '',
          actionWl: data?.actions.audit?.useBulletinWhitelists
            ? 'audit.useBulletinWhitelists'
            : false,
          actionI: data?.actions.inventorization ? 'inventorization' : '',
          actionAgentU: data?.actions.agent_update ? 'agent_update' : '',
        });
      } else if (
        (data?.taskType === 'host' || data?.taskType === 'asset') &&
        data.actions.package_update &&
        !data.actions.kb_install
      ) {
        const pkgs = data.actions.package_update.packages?.map((p) => ({
          name: p.name,
          packetVersion: undefined,
        }));
        const { rebootBehaviorAfterUpdate } = data.actions.package_update;

        if (rebootBehaviorAfterUpdate)
          setPkgRebootVal({
            text: kbRebootData[currentLocale].find((v) => v.id === rebootBehaviorAfterUpdate)!.text,
            id: rebootBehaviorAfterUpdate,
          });
        setSelectedPkgs(pkgs || null);
        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'asset',
          actionA: data?.actions.audit ? 'audit' : '',
          actionWl: data?.actions.audit?.useBulletinWhitelists
            ? 'audit.useBulletinWhitelists'
            : false,
          actionI: data?.actions.inventorization ? 'inventorization' : '',
          actionAgentU: data?.actions.agent_update ? 'agent_update' : '',
          actionPackagetU: 'package_update',
        });
      } else if (
        (data?.taskType === 'host' || data?.taskType === 'asset') &&
        !data.actions.package_update &&
        data.actions.kb_install
      ) {
        const kbs = data.actions.kb_install?.kbList?.map((k) => ({
          id: k.id,
          title: '',
        }));
        const { updateFrom, rebootBehaviorAfterUpdate } = data.actions.kb_install;

        if (updateFrom)
          setKbUpdateSrcVal({
            text: kbUpdateSrcData[currentLocale].find((v) => v.id === updateFrom)!.text,
            id: updateFrom,
          });
        if (rebootBehaviorAfterUpdate)
          setKbRebootVal({
            text: kbRebootData[currentLocale].find((v) => v.id === rebootBehaviorAfterUpdate)!.text,
            id: rebootBehaviorAfterUpdate,
          });
        setMissedKbs(kbs || null);
        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'asset',
          actionA: data?.actions.audit ? 'audit' : '',
          actionWl: data?.actions.audit?.useBulletinWhitelists
            ? 'audit.useBulletinWhitelists'
            : false,
          actionI: data?.actions.inventorization ? 'inventorization' : '',
          actionAgentU: data?.actions.agent_update ? 'agent_update' : '',
          actionKbInstall: 'kb_install',
        });
      } else if (data?.taskType === 'device') {
        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'device',
          actionA: data?.actions.audit ? 'audit' : '',
          actionI: data?.actions.inventorization ? 'inventorization' : '',
          actionWl: data?.actions.audit?.useBulletinWhitelists
            ? 'audit.useBulletinWhitelists'
            : false,
        });
      } else if (data.taskType === 'report') {
        setReportType({
          value: reportTypeValue[currentLocale].filter(
            (v) =>
              v.id === data.actions.single_report?.reportType ||
              v.id === data.actions.group_report?.reportType,
          )[0],
        });

        if (data.actions.group_report?.reportType === 'bulletin_overview') {
          setSelectedBulletinIds([
            ...data.actions.group_report.bulletinIds.map((b) => ({ id: b })),
          ]);
        }

        if (data.actions.single_report?.reportType === 'bulletin_overview') {
          setSelectedBulletinIds([
            ...data.actions.single_report.bulletinIds.map((b) => ({ id: b })),
          ]);
        }

        if (
          data.actions.group_report?.reportType === 'top_bulletins' ||
          data.actions.group_report?.reportType === 'top_vulnerable_assets'
        ) {
          setTopN(data.actions.group_report.topN);
        }

        if (data && data.actions && 'single_report' in data.actions) {
          if (data.actions.single_report?.fileFormat.find((f) => f === 'pdf')) {
            setPdfFormat(true);
          }
        }

        if (data && data.actions && 'group_report' in data.actions) {
          if (data.actions.group_report?.fileFormat.find((f) => f === 'pdf')) {
            setPdfFormat(true);
          }
        }

        if (data && data.actions && 'single_report' in data.actions) {
          if (data.actions.single_report?.fileFormat.find((f) => f === 'html')) {
            setHtmlFormat(true);
          }
        }

        if (data && data.actions && 'group_report' in data.actions) {
          if (data.actions.group_report?.fileFormat.find((f) => f === 'html')) {
            setHtmlFormat(true);
          }
        }

        if (
          data.taskType === 'report' &&
          data.actions.single_report &&
          'dateRange' in data.actions.single_report
        ) {
          handleFromTaskAction(new Date(data.actions.single_report.dateRange.from));
          handleToTaskAction(new Date(data.actions.single_report.dateRange.to));
        }

        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'report',
          actionGR: data?.actions.group_report ? 'group_report' : '',
          actionCR: data?.actions.single_report ? 'single_report' : '',
        });

        if (
          data.actions.notification?.email?.users &&
          data.actions.notification?.email?.users.length > 0
        ) {
          const allUsers = getAllUsers();
          const usersForTask: Pick<User, 'email' | 'userId' | 'auth'>[] | null = [];
          allUsers.then((value) => {
            value.data.forEach((u) => {
              if (data.actions.notification?.email?.users?.includes(u.userId)) {
                const userValue = {
                  email: u.email,
                  userId: u.userId,
                  auth: u.auth,
                };
                usersForTask.push(userValue);
              }
            });
            if (usersForTask.length > 0) {
              setUserTaskEmail(usersForTask);
            }
          });
        }

        if (
          data.actions.notification?.email?.recipientEmails &&
          data.actions.notification?.email?.recipientEmails.length > 0
        ) {
          setUserTaskInputEmail(data.actions.notification?.email?.recipientEmails);
        }
      } else if (data.taskType === 'image') {
        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'asset',
          actionAImage: data?.actions.audit_image ? 'audit_image' : '',
          actionA: 'audit',
        });
      }

      if (data.taskType === 'asset_synchronize') {
        console.log(data);
        if (data.scope.ldap) {
          const serverData = {
            ldapId: data.scope.ldap.ldapId || '',
            name: data.scope.ldap.name,
          };
          setServerForm([serverData]);
        }

        if ('asset_ldap_synchronize' in data.actions) {
          const ldapData: TLdapGrFormValues[] | null = [];

          if (data.actions.asset_ldap_synchronize?.bindCredentials) {
            const dataCredInTask: TCommonCredInTask[] = [];
            data.actions.asset_ldap_synchronize?.bindCredentials.forEach((g) => {
              const cred = {
                credentialName: g.name,
                credentialId: g.credentialId,
                type: g.type,
              };
              dataCredInTask.push(cred);
            });

            setCommonCredInTask(dataCredInTask);
          }

          if (data.actions.asset_ldap_synchronize?.ldapGroups) {
            data.actions.asset_ldap_synchronize?.ldapGroups.forEach((g) => {
              if (g.assetGroupPolicy === 'create') {
                const ldapGrData = {
                  ldapGroupName: g.ldapGroupName,
                  newGr: g.ldapGroupName,
                  assetGr: null,
                  assetGroupPolicy: g.assetGroupPolicy,
                  type: data.scope.ldap.type,
                } as TLdapGrFormValues;
                ldapData.push(ldapGrData);
              }
              if (g.assetGroupPolicy === 'add_to_existing') {
                const ldapGrData = {
                  ldapGroupName: g.ldapGroupName,
                  newGr: null,
                  assetGr: {
                    name: g.existingAssetGroup?.name,
                    id: g.existingAssetGroup?.assetGroupId,
                  },
                  assetGroupPolicy: g.assetGroupPolicy,
                  type: data.scope.ldap.type,
                } as TLdapGrFormValues;
                ldapData.push(ldapGrData);
              }
              if (g.assetGroupPolicy === 'do_not_create') {
                const ldapGrData = {
                  ldapGroupName: g.ldapGroupName,
                  newGr: null,
                  assetGr: null,
                  assetGroupPolicy: g.assetGroupPolicy,
                  type: data.scope.ldap.type,
                } as TLdapGrFormValues;
                ldapData.push(ldapGrData);
              }
            });
          }

          if (ldapData) setLdapGrForm(ldapData);

          if (data.actions.asset_ldap_synchronize?.assetDuplicationsSearchPolicy === 'globally') {
            setDuplicate('globalCheck');
          } else if (
            data.actions.asset_ldap_synchronize?.assetDuplicationsSearchPolicy ===
            'inside_asset_group'
          ) {
            setDuplicate('inGroupCheck');
          } else {
            setDuplicate('doNotCheck');
          }
        }

        setTaskRepeatData({
          taskName: data?.taskName,
          taskType: 'asset_synchronize',
        });
      }

      setOpenDialog(true);
    }
  };

  const repeatTaskDetails = (
    data: EnrichedTaskActionResponse | undefined,
    type: string,
    taskName: string,
  ): void => {
    if (
      data &&
      data.type === 'inventorization' &&
      data.assetId &&
      typeof data.assetId === 'string' &&
      data.assets &&
      'latestInventorization' in data.assets[0] &&
      data.assets[0].type === 'host' &&
      data.assets[0].latestInventorization &&
      data.assets[0].latestInventorization.hostname
    ) {
      setHostForm([
        {
          assetId: data?.assetId,
          hostname: data.assets[0].latestInventorization.hostname,
        },
      ]);
      setTaskRepeatData({
        taskName,
        taskType: 'asset',
        actionI: type === 'inventorization' ? 'inventorization' : '',
        actionA: type === 'audit' ? 'audit' : '',
      });
    } else if (
      data &&
      data.type === 'inventorization' &&
      data.assetId &&
      typeof data.assetId === 'string' &&
      data.assets &&
      'latestInventorization' in data.assets[0] &&
      (data.assets[0].type === 'firewall' ||
        data.assets[0].type === 'router' ||
        data.assets[0].type === 'switch' ||
        data.assets[0].type === 'hypervisor') &&
      data.assets[0].latestInventorization &&
      data.assets[0].latestInventorization.hostname
    ) {
      setDeviceForm([
        {
          assetId: data.assetId,
          hostname: data.assets[0].latestInventorization.hostname
            ? data.assets[0].latestInventorization.hostname
            : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName,
        taskType: 'asset',
        actionI: type === 'inventorization' ? 'inventorization' : '',
        actionA: type === 'audit' ? 'audit' : '',
      });
    }

    setOpenDialog(true);
  };

  const repeatAuditTask = (
    data: AuditDashboardResponse | undefined,
    type: string,
    wl: string | false,
    taskName: string,
  ): void => {
    if (
      data?.asset.type === 'host' ||
      data?.asset.type === 'firewall' ||
      data?.asset.type === 'router' ||
      data?.asset.type === 'switch' ||
      data?.asset.type === 'hypervisor'
    ) {
      setHostForm([
        {
          assetId: data?.assetId,
          hostname:
            'latestInventorization' in data.asset && data.asset.latestInventorization?.hostname
              ? data.asset.latestInventorization.hostname
              : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName,
        taskType: 'asset',
        actionI: type === 'inventorization' ? 'inventorization' : '',
        actionA: type === 'audit' ? 'audit' : '',
        actionWl: wl,
      });
    }

    if (data?.asset.type === 'image') {
      setImageForm([
        {
          imageNames: 'imageNames' in data.asset ? data.asset.imageNames[0] : '',
          assetId: data?.assetId,
        },
      ]);
      setTaskRepeatData({
        taskName,
        taskType: 'asset',
        actionI: type === 'inventorization' ? 'inventorization' : '',
        actionA: type === 'audit' ? 'audit' : '',
        actionWl: wl,
        actionAImage: 'audit_image',
      });
    }

    setOpenDialog(true);
  };

  return {
    repeatTaskDescription,
    repeatTaskDetails,
    repeatAuditTask,
  };
}
