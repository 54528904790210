/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '../../../common/baseElements/Button';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useServerContext } from '../../../../hooks/useServerContext';
import styles from './NewTask.module.scss';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { CommonTooltip } from '../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function ImportSyncBlockTask(): React.ReactElement {
  const { setOpenSrvMutableBlock, serverForm, setServerForm } = useServerContext();

  const { selectCredential } = useHostActions();

  const { commonCredInTask, setCommonCredInTask } = useTaskAddContext();

  const delCredFromCommonCreds = (id: string): void => {
    const mutableCommonCreds = commonCredInTask?.filter((c) => c.credentialId !== id);
    if (mutableCommonCreds) setCommonCredInTask(mutableCommonCreds);
  };

  return (
    <div className={styles.applyCreds}>
      <div className={styles.titleTaskWrapTooltip}>
        <label className="k-label">
          {localeNewTask[currentLocale].form.synchronize.authPrameters}
        </label>
        <div className={styles.tooltipWrap}>
          <CommonTooltip>{localeNewTask[currentLocale].form.synchronize.authTooltip}</CommonTooltip>
        </div>
      </div>
      <div className={styles.applyCredsWrap}>
        <div className={styles.applyCredsRow}>
          <span className={`${styles.applyCredsElementTitle} required-label`}>
            {localeNewTask[currentLocale].form.synchronize.srv}
          </span>
          <div className={styles.applyCredsElementContent}>
            {serverForm && serverForm[0].name && (
              <div className={styles.applyCredsElementNameWrap} title={serverForm[0].name || ''}>
                <span>{serverForm[0].name}</span>
              </div>
            )}
            {!serverForm ? (
              <Button
                className={`${styles.add_button} ${styles.btnCred}`}
                onClick={(): void => setOpenSrvMutableBlock(true)}
                type="button"
                fill="menu"
                icon="plus"
              >
                {localeNewTask[currentLocale].form.selectServer}
              </Button>
            ) : (
              <div className="dropdown__container">
                <span className="k-icon k-i-more-vertical" />
                <div className={`dropdown__content ${styles.dropdownContent}`}>
                  <Button
                    type="button"
                    fill="menu"
                    icon="repeat"
                    onClick={(): void => setOpenSrvMutableBlock(true)}
                  >
                    {localeNewTask[currentLocale].form.changeServer}
                  </Button>
                  <Button
                    fill="menu"
                    onClick={(): void => setServerForm(null)}
                    className={styles.credentials_button}
                    icon="delete"
                  >
                    {localeNewTask[currentLocale].delete}{' '}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.applyCredsRow}>
          <span className={styles.applyCredsElementTitle}>
            {localeNewTask[currentLocale].form.synchronize.credSSH}
          </span>
          <div className={styles.applyCredsElementContent}>
            {commonCredInTask && commonCredInTask.find((c) => c.type === 'ssh') && (
              <div
                className={styles.applyCredsElementNameWrap}
                title={commonCredInTask.filter((c) => c.type === 'ssh')[0].credentialName || ''}
              >
                <span>{commonCredInTask.filter((c) => c.type === 'ssh')[0].credentialName}</span>
              </div>
            )}
            {!commonCredInTask?.find((c) => c.type === 'ssh') ? (
              <Button
                className={`${styles.add_button} ${styles.btnCred}`}
                onClick={(): void =>
                  selectCredential({
                    assetType: 'commonssh',
                  })
                }
                type="button"
                fill="menu"
                icon="plus"
              >
                {localeNewTask[currentLocale].form.selectServer}
              </Button>
            ) : (
              <div className="dropdown__container">
                <span className="k-icon k-i-more-vertical" />
                <div className={`dropdown__content ${styles.dropdownContent}`}>
                  <Button
                    fill="menu"
                    icon="repeat"
                    type="button"
                    onClick={(): void =>
                      selectCredential({
                        assetType: 'commonssh',
                      })
                    }
                  >
                    {localeNewTask[currentLocale].form.changeServer}
                  </Button>
                  <Button
                    fill="menu"
                    onClick={(): void =>
                      delCredFromCommonCreds(
                        commonCredInTask.filter((c) => c.type === 'ssh')[0].credentialId,
                      )
                    }
                    className={styles.credentials_button}
                    icon="delete"
                  >
                    {localeNewTask[currentLocale].delete}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.applyCredsRow}>
          <span className={styles.applyCredsElementTitle}>
            {localeNewTask[currentLocale].form.synchronize.credWinrm}
          </span>
          <div className={styles.applyCredsElementContent}>
            {commonCredInTask && commonCredInTask.find((c) => c.type === 'winrm') && (
              <div
                className={styles.applyCredsElementNameWrap}
                title={commonCredInTask.filter((c) => c.type === 'winrm')[0].credentialName || ''}
              >
                <span>{commonCredInTask.filter((c) => c.type === 'winrm')[0].credentialName}</span>
              </div>
            )}
            {!commonCredInTask?.find((c) => c.type === 'winrm') ? (
              <Button
                className={`${styles.add_button} ${styles.btnCred}`}
                onClick={(): void =>
                  selectCredential({
                    assetType: 'commonwinrm',
                  })
                }
                fill="menu"
                icon="plus"
                type="button"
              >
                {localeNewTask[currentLocale].form.selectServer}
              </Button>
            ) : (
              <div className="dropdown__container">
                <span className="k-icon k-i-more-vertical" />
                <div className={`dropdown__content ${styles.dropdownContent}`}>
                  <Button
                    fill="menu"
                    icon="repeat"
                    type="button"
                    onClick={(): void =>
                      selectCredential({
                        assetType: 'commonwinrm',
                      })
                    }
                  >
                    {localeNewTask[currentLocale].form.changeServer}
                  </Button>
                  <Button
                    fill="menu"
                    onClick={(): void =>
                      delCredFromCommonCreds(
                        commonCredInTask.filter((c) => c.type === 'winrm')[0].credentialId,
                      )
                    }
                    className={styles.credentials_button}
                    icon="delete"
                  >
                    {localeNewTask[currentLocale].delete}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className={styles.selectedSrv}>
        {serverForm && (
          <table className={`${styles.selectedSrvTable} horizontal`}>
            <thead>
              <tr>
                <th style={{ width: '188px' }}>
                  {localeNewTask[currentLocale].form.applyForServer.name}
                </th>
                <th style={{ width: '188px' }}>URL</th>
                <th>{localeNewTask[currentLocale].form.applyForServer.cred}</th>
                <th className={styles.delete_button_th}> </th>
              </tr>
            </thead>
            <tbody>
              <tr className="cred-in-add-form">
                <td>{serverForm[0].name}</td>
                <td>{serverForm[0].url}</td>
                <td>
                  {serverForm[0].credential?.name ? (
                    serverForm[0].credential?.name
                  ) : (
                    <span className={styles.srvEmptyCred}>
                      {localeNewTask[currentLocale].form.applyForServer.credEmpty}
                    </span>
                  )}
                </td>
                <td>
                  <Button fill="flat" textColor="green" className={styles.delete_button}>
                    <span className="k-icon k-icon k-i-x" onClick={resetSrv} />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div> */}
    </div>
  );
}
