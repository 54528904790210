import type { CVSSVectorV4 } from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import styles from './vectorModal.module.scss';
import localeCvss from '../../../../utils/i18n/cvssLocales/vectorMetricsLocale.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCvss;

export function ScoresInfoBlockV4({
  data,
}: {
  // data: Exclude<DecodedCVSSVector['cvss'], undefined>;
  data: CVSSVectorV4;
}): React.ReactElement {
  const { version, score, rating, macroVector, ...globalMetrics } = data;
  // const cvssVersion = parseInt(version, 10);

  return (
    <div className="base">
      {Object.values(globalMetrics).map(({ name: gName, ...gMetrics }) => (
        <>
          <p className={styles.metric_title}>{gName}</p>
          {Object.values(gMetrics).map(({ name: lName, ...lMetrics }: any) => (
            <>
              {lName && <p className="submetric-title">{lName}:</p>}
              <table className={styles.metric_info_table}>
                {Object.values(lMetrics).map((metric: any) => (
                  <tr>
                    <td className={styles.metric_name}>
                      <li>
                        {metric.name} ({metric.abbreviatedForm.name}):
                      </li>
                    </td>
                    <td>
                      <span className={styles.score_values}>
                        {Object.entries(
                          localeCvss[currentLocale]['4'][metric.abbreviatedForm.name as 'AV']
                            .values,
                        )
                          // scoreMetricsData[cvssVersion][metric.abbreviatedForm.name]
                          // .filter((scoreName) => scoreName !== metric.value)
                          .map(([scoreAbbrevName, scoreName]) =>
                            scoreAbbrevName === metric.abbreviatedForm.value ? (
                              <span className={styles.metric_score_value}>
                                {scoreName} ({metric.abbreviatedForm.name}:
                                {metric.abbreviatedForm.value})
                              </span>
                            ) : (
                              <span className="alternative-score-name-info">{scoreName}</span>
                            ),
                          )}
                      </span>
                      <span className={styles.metric_description}>{metric.description}</span>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          ))}
        </>
      ))}
    </div>
  );
}
