import type { ReactElement } from 'react';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import styles from './vectorModal.module.scss';
import type {
  CVSSVectorV2,
  CVSSVectorV3,
} from '../../../../types/__generated/cloud/common/decodedCVSSVector.v1';
import { returnClassName } from '../../../../lib/axios/helpers';
import localeCvss from '../../../../utils/i18n/cvssLocales/vectorMetricsLocale.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCvss;

export function VectorInfographicsV3({
  cvss,
}: {
  cvss: CVSSVectorV2 | CVSSVectorV3;
}): ReactElement {
  function calculateCvssScoreColor(score: number): string {
    if (score >= 0 && score <= 3.9) return '#00bb00';
    if (score >= 3.9 && score <= 6.9) return '#ffb800';
    if (score >= 6.9 && score <= 8.9) return '#ff6200';
    if (score > 8.9) return '#dc0000';

    return '#000000';
  }

  return (
    <div className={styles.vector_info}>
      <table className={`${styles.scores_info} ${styles.info_block}`}>
        <tr>
          <td className={styles.score_name}>{localeCvss[currentLocale].scores.base}:</td>
          <td>
            <span className={returnClassName(cvss.base.score.toString())}>{cvss?.base.score}</span>
          </td>
        </tr>
        <tr>
          <td>{localeCvss[currentLocale].scores.impact}:</td>
          <td>
            <span className={returnClassName(cvss.base.impact.subscore.toString())}>
              {cvss.base.impact.subscore || '0.0'}
            </span>
          </td>
        </tr>
        <tr>
          <td>{localeCvss[currentLocale].scores.exploitability}:</td>
          <td>
            <span className={returnClassName(cvss.base.exploitability.subscore.toString())}>
              {cvss.base.exploitability.subscore || '0.0'}
            </span>
          </td>
        </tr>
        <tr>
          <td className={styles.score_name}>{localeCvss[currentLocale].scores.temporal}:</td>
          <td>
            <span className={returnClassName(cvss.temporal?.score.toString())}>
              {cvss.temporal?.score || '0.0'}
            </span>
          </td>
        </tr>
        <tr>
          <td>{localeCvss[currentLocale].scores.environmental}:</td>
          <td>
            <span className={returnClassName(cvss.environmental?.score?.toString())}>
              {cvss.environmental?.score || '0.0'}
            </span>
          </td>
        </tr>
        <tr>
          <td>{localeCvss[currentLocale].scores.mImpact}:</td>
          <td>
            <span
              className={returnClassName(
                cvss.environmental?.securityRequirements?.subscore?.toString(),
              )}
            >
              {cvss.environmental?.securityRequirements?.subscore || '0.0'}
            </span>
          </td>
        </tr>
        <tr>
          <td className={styles.score_name}>{localeCvss[currentLocale].scores.overall}:</td>
          <td>
            <span className={returnClassName(cvss.score.toString())}>{cvss.score || '0.0'}</span>
          </td>
        </tr>
      </table>
      <div className={styles.info_block}>
        <Chart style={{ width: '240px', height: '240px' }}>
          <ChartTitle text={localeCvss[currentLocale].graphicNames.base} />
          <ChartValueAxis>
            <ChartValueAxisItem min={0} max={10} />
          </ChartValueAxis>
          {/* <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={['Base', 'Impact', 'Exploitability']} />
                </ChartCategoryAxis> */}
          <ChartSeries>
            <ChartSeriesItem
              data={[
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.base,
                  score: cvss.base.score || 0,
                  color: calculateCvssScoreColor(cvss.base.score || 0),
                  test: '10',
                },
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.impact,
                  score: cvss.base.impact.subscore || 0,
                  color: calculateCvssScoreColor(cvss.base.impact.subscore || 0),
                  test: '10',
                },
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.exploitability,
                  score: cvss.base.exploitability.subscore || 0,
                  color: calculateCvssScoreColor(cvss.base.exploitability.subscore || 0),
                  test: '10',
                },
              ]}
              tooltip={{ visible: true, color: '#33333', background: '#000000' }}
              type="column"
              field="score"
              categoryField="metricGroupName"
              colorField="color"
              gap={1}
              upperField="test"
            />
          </ChartSeries>
        </Chart>
      </div>
      <div className={styles.info_block}>
        <Chart style={{ width: '100px', height: '240px', zIndex: '0' }}>
          <ChartValueAxis>
            <ChartValueAxisItem min={0} max={10} />
          </ChartValueAxis>
          <ChartTitle text={localeCvss[currentLocale].graphicNames.temporal} />
          {/* <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={['asd', 'ddd']} />
                </ChartCategoryAxis> */}
          <ChartSeries>
            <ChartSeriesItem
              // labels={{ align: 'center', visible: true }}
              data={[
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.temporal,
                  score: cvss.temporal?.score || 0,
                  color: calculateCvssScoreColor(cvss.temporal?.score || 0),
                },
              ]}
              tooltip={{ visible: true, color: '#33333', background: '#000000' }}
              type="column"
              field="score"
              categoryField="metricGroupName"
              colorField="color"
              gap={1}
            />
          </ChartSeries>
        </Chart>
      </div>
      <div className={styles.info_block}>
        <Chart style={{ width: '200px', height: '254px', zIndex: '0' }}>
          <ChartValueAxis>
            <ChartValueAxisItem min={0} max={10} />
          </ChartValueAxis>
          <ChartTitle text={localeCvss[currentLocale].graphicNames.environmental} />
          <ChartSeries>
            <ChartSeriesItem
              // labels={{ align: 'center', visible: true }}
              data={[
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.environmental,
                  score: cvss.environmental?.score || 0,
                  color: calculateCvssScoreColor(cvss.environmental?.score || 0),
                },
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.mImpact,
                  score: cvss.environmental?.securityRequirements?.subscore || 0,
                  color: calculateCvssScoreColor(
                    cvss.environmental?.securityRequirements?.subscore || 0,
                  ),
                },
              ]}
              tooltip={{ visible: true, color: '#33333', background: '#000000' }}
              type="column"
              field="score"
              categoryField="metricGroupName"
              colorField="color"
              gap={1}
              minSize={10}
            />
          </ChartSeries>
        </Chart>
      </div>
      <div className={styles.info_block}>
        <Chart style={{ width: '100px', height: '240px', zIndex: '0' }}>
          <ChartValueAxis>
            <ChartValueAxisItem min={0} max={10} />
          </ChartValueAxis>
          <ChartTitle text={localeCvss[currentLocale].graphicNames.overall} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={['asd', 'ddd']} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              data={[
                {
                  metricGroupName: localeCvss[currentLocale].graphicNames.overall,
                  score: cvss.score || 0,
                  color: calculateCvssScoreColor(cvss.score || 0),
                },
              ]}
              tooltip={{ visible: true, color: '#33333', background: '#000000' }}
              type="column"
              field="score"
              categoryField="metricGroupName"
              colorField="color"
              gap={1}
            />
          </ChartSeries>
        </Chart>
      </div>
    </div>
  );
}
