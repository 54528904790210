import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter } from '@progress/kendo-react-data-tools';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router';
import type { IDataHooks } from '../../../utils/helpers/types';
import { getUrlUser } from '../../../utils/helpers/user-list-helper';
import { useUserList } from '../../../hooks/components/useUserList';
import { CommonUserList } from '../../common/CommonUserList';
import { Input } from '../../common/baseElements/Input';
import { FILTER_USER_LIST } from '../../../utils/helpers/constant-serv-filter-host';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import styles from './UserList.module.scss';
import localeUser from '../../../utils/i18n/userLocales/userList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function UserListForServer(): React.ReactElement {
  const urlParams = getUrlUser('UserListForServer');

  const { ldapId } = useParams<{ ldapId: string }>();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'UserListForServer',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    data,
    handleCustomSearch,
    customSearch,
  } = useUserList(urlParams, dataForHooks, ldapId);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className={styles.userListForServer}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_USER_LIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  className="k-input custom-search"
                  name="customSearch"
                  placeholder={localeUser[currentLocale].modal.placeHolderCustomSearch}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonUserList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
        />
      </div>
    </ErrorBoundary>
  );
}
