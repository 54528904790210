/* eslint-disable react/jsx-pascal-case */
import { Field } from '@progress/kendo-react-form';
import { loadMessages } from '@progress/kendo-react-intl';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { FormInput } from '../../../common/form/FormInput';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { checkLength } from '../../../../utils/helpers/validation';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function LoginPassForm(): React.ReactElement {
  const {
    username,
    setUsername,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    credentialData,
    isEdit,
    typeCred,
  } = useCredentialContext();

  return (
    <div className="form-content">
      <Field
        name="username"
        component={FormInput}
        id="username"
        label={locale[currentLocale].form.userName}
        onChange={(e): void => {
          setUsername(e.target.value);
        }}
        inputValue={username}
        className="form-field-wrapper"
        customValidator={checkLength}
        type="text"
        classForLabel="required-label"
        tooltipText={
          typeCred === 'activedirectory' ? locale[currentLocale].form.adExampleName : null
        }
        tooltipPosition={{ marginTop: '-20px', marginLeft: '158px' }}
      />
      <Field
        name="password"
        component={FormInput}
        id="password"
        label={locale[currentLocale].form.password}
        onChange={(e): void => {
          setPassword(e.target.value);
        }}
        inputValue={password}
        className="form-field-wrapper"
        customValidator={!credentialData && !isEdit && checkLength}
        type="password"
        classForLabel="required-label"
      />
      <Field
        name="passwordConfirm"
        component={FormInput}
        id="passwordConfirm"
        label={locale[currentLocale].form.repeatPass}
        onChange={(e): void => {
          setPasswordConfirm(e.target.value);
        }}
        inputValue={passwordConfirm}
        className="form-field-wrapper"
        customValidator={!credentialData && !isEdit && checkLength}
        type="password"
        classForLabel="required-label"
      />
      {password && passwordConfirm && password !== passwordConfirm && (
        <div className="error-common-field">
          <span>{locale[currentLocale].passNotMatch}</span>
        </div>
      )}
    </div>
  );
}
