import type { GridCellProps } from '@progress/kendo-react-grid';

export function WithoutGrCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  console.log(dataItem);

  const value = !dataItem.assetGr && !dataItem.newGr ? '+' : '-';

  return <td style={{ position: 'relative' }}>{value}</td>;
}
