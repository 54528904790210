import { TextFilter } from '@progress/kendo-react-data-tools';
import { FilterCalendar } from '../../components/common/filterComponents/FilterCalendar';
import { FilterCredentialSelect } from '../../components/common/filterComponents/FilterCredentialSelect';
import { FilterInputCvss } from '../../components/common/filterComponents/FilterInputCvss';
import { FilterInputInteger } from '../../components/common/filterComponents/FilterInputInteger';
import {
  FILTER_CUSTOM_OPERATORS,
  FILTER_OPERATORS,
  FILTER_TASK_OPERATORS,
  FILTER_TEXT_CONTAINS,
  FILTER_TEXT_EQ,
} from './constant-filter-operators';
import { FilterStatusAndType } from '../../components/common/filterComponents/FilterStatusAndType';
import { FilterDeviceAndUser } from '../../components/common/filterComponents/FilterDeviceAndUser';
import { FilterUserStatus } from '../../components/common/filterComponents/FilterUserStatus';
import { FilterTagSelect } from '../../components/common/filterComponents/FilterTagSelect';
import { FilterStatusForReg } from '../../components/common/filterComponents/FilterStatusForReg';
import { FilterStatusForDev } from '../../components/common/filterComponents/FilterStatusForDev';
import { FilterServerType } from '../../components/common/filterComponents/FilterServerType';

export const FILTER_HOSTLIST = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Доменное имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Обновлений',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Имя ОС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.version',
      label: 'Версия ОС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'ID бюллетеня',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'ID бюллетеня из БС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'assetId',
      label: 'ID актива',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'tags.name',
      label: 'Имя тега',
      filter: FilterTagSelect,
      operators: FILTER_OPERATORS.textRestrict1,
    },
    {
      name: 'softwareName',
      label: 'Наименование уст. ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Наименование уязвимого ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Версия ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'agentSoftwareVersion',
      label: 'Версия агента',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Название организации',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Давность аудита',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Domain name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Updates count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'OS Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.version',
      label: 'OS Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'Bulletin Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'Bulletin from exceptions',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'assetId',
      label: 'Asset Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'tags.name',
      label: 'Tag name',
      filter: FilterTagSelect,
      operators: FILTER_OPERATORS.textRestrict1,
    },
    {
      name: 'softwareName',
      label: 'Name of installed soft',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Name of vulnerable soft',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Software version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'agentSoftwareVersion',
      label: 'Agent version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Account name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
};
export const FILTER_HOSTLIST_NOT_SUPER_ADMIN = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Доменное имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Обновлений',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Имя ОС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.version',
      label: 'Версия ОС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'ID бюллетеня',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'ID бюллетеня из БС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'assetId',
      label: 'ID актива',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'tags.name',
      label: 'Имя тега',
      filter: FilterTagSelect,
      operators: FILTER_OPERATORS.textRestrict1,
    },
    {
      name: 'softwareName',
      label: 'Наименование уст. ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Наименование уязвимого ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Версия ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'agentSoftwareVersion',
      label: 'Версия агента',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Давность аудита',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Domain name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Updates count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'OS Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.version',
      label: 'OS Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'Bulletin Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'Bulletin from exceptions',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'assetId',
      label: 'Asset Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'tags.name',
      label: 'Tag name',
      filter: FilterTagSelect,
      operators: FILTER_OPERATORS.textRestrict1,
    },
    {
      name: 'softwareName',
      label: 'Name of installed soft',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Name of vulnerable soft',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Software version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'agentSoftwareVersion',
      label: 'Agent version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
};
export const FILTER_IMAGELIST = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageNames',
      label: 'Имена образов',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'imageId',
      label: 'ID образа',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Система',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetHostsCount',
      label: 'Хостов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'registriesCount',
      label: 'Реестров',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'bulletinId',
      label: 'Бюллетень ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'ID бюллетеня из БС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'softwareName',
      label: 'Наименование уст. ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Наименование уязвимого ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Версия ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Название организации',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Давность аудита',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageNames',
      label: 'Repository tags',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'imageId',
      label: 'Image ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'System',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetHostsCount',
      label: 'Hosts',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'registriesCount',
      label: 'Registry count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'bulletinId',
      label: 'Bulletin Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'Bulletin Id from WL',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'softwareName',
      label: 'Name of installed software',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Name of vulnerable software',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Software version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Account name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
};

export const FILTER_IMAGELIST_NOT_SUPER_ADMIN = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageNames',
      label: 'Имена образов',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'imageId',
      label: 'ID образа',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Система',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetHostsCount',
      label: 'Хостов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'registriesCount',
      label: 'Реестров',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'bulletinId',
      label: 'Бюллетень ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'ID бюллетеня из БС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'softwareName',
      label: 'Наименование уст. ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Наименование уязвимого ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Версия ПО',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Давность аудита',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageNames',
      label: 'Repository tags',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'imageId',
      label: 'Image ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'System',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetHostsCount',
      label: 'Hosts',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'registriesCount',
      label: 'Registry count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'bulletinId',
      label: 'Bulletin Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'Bulletin Id from WL',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'softwareName',
      label: 'Name of installed software',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'vulnerableSoftwareName',
      label: 'Name of vulnerable software',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textСontains1,
    },
    {
      name: 'softwareVersion',
      label: 'Software version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
};

export const FILTER_IMAGELIST_MODAL = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageNames',
      label: 'Имена образов',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'imageId',
      label: 'ID образа',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Система',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetHostsCount',
      label: 'Хостов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'registriesCount',
      label: 'Реестров',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imageNames',
      label: 'Repository tags',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'imageId',
      label: 'Image ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'System',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetHostsCount',
      label: 'Hosts',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'registriesCount',
      label: 'Registry count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
};
export const FILTER_DEVICELIST = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Имя устройства',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимости',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'type',
      label: 'Тип устройства',
      filter: FilterStatusForDev,
      operators: FILTER_CUSTOM_OPERATORS.text0,
    },
    {
      name: 'class',
      label: 'Производитель',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'OS',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.model.name',
      label: 'Модель',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'Бюллетень ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'ID бюллетеня из БС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Название организации',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Давность аудита',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Hostname',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'type',
      label: 'Type of device',
      filter: FilterStatusForDev,
      operators: FILTER_CUSTOM_OPERATORS.text0,
    },
    {
      name: 'class',
      label: 'Vendor',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'OS',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.model.name',
      label: 'Model',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'Bulletin Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'Bulletin Id from WL',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Account name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
};
export const FILTER_DEVICELIST_NOT_SUPER_ADMIN = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Имя устройства',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимости',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'type',
      label: 'Тип устройства',
      filter: FilterStatusForDev,
      operators: FILTER_CUSTOM_OPERATORS.text0,
    },
    {
      name: 'class',
      label: 'Производитель',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'OS',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.model.name',
      label: 'Модель',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'Бюллетень ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'ID бюллетеня из БС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Давность аудита',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.maxVFstec',
      label: 'V',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Hostname',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'type',
      label: 'Type of device',
      filter: FilterStatusForDev,
      operators: FILTER_CUSTOM_OPERATORS.text0,
    },
    {
      name: 'class',
      label: 'Vendor',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'OS',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.model.name',
      label: 'Model',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'bulletinId',
      label: 'Bulletin Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'whitelistedBulletinId',
      label: 'Bulletin Id from WL',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.createdAt',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.dateWithoutNeq,
    },
  ],
};
export const FILTER_REGISTRY_LIST = {
  'ru-RU': [
    {
      name: 'type',
      label: 'Тип',
      filter: FilterStatusForReg,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'name',
      label: 'Имя реестра',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'address',
      label: 'URL',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'repositoriesCount',
      label: 'Репозиториев',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imagesCount',
      label: 'Образов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAuditTime',
      label: 'Последний аудит',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'type',
      label: 'Type',
      filter: FilterStatusForReg,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'name',
      label: 'Registry name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'address',
      label: 'URL',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'repositoriesCount',
      label: 'Repositories',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'imagesCount',
      label: 'Images',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAuditTime',
      label: 'Latest audit',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const FILTER_ASSETLIST_WITHOUT_ID = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Доменное имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Обновлений',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Система',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.version',
      label: 'Версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvss,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Domain name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Updates',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'System',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.os.version',
      label: 'Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};

export const FILTER_LIST_GROUP = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя группы',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'assetCount',
      label: 'Хостов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'description',
      label: 'Описание',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Дата создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'createdBy.name',
      label: 'Кем создана',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'modifiedAt',
      label: 'Дата изменения',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'modifiedBy.name',
      label: 'Кем изменена',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'assetCount',
      label: 'Hosts',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'description',
      label: 'Description',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Created at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'createdBy.name',
      label: 'Кем создана',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'modifiedAt',
      label: 'Modified at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'modifiedBy.name',
      label: 'Modified by',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};

export const FILTER_LIST_CREDENTIALS = {
  'ru-RU': [
    {
      name: 'type',
      label: 'Тип',
      filter: FilterCredentialSelect,
      operators: FILTER_TEXT_EQ.text,
    },
    {
      name: 'name',
      label: 'Имя учётной записи',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdBy.name',
      label: 'Кем создана',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'credentialId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Описание',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Дата создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'type',
      label: 'Type',
      filter: FilterCredentialSelect,
      operators: FILTER_TEXT_EQ.text,
    },
    {
      name: 'name',
      label: 'Account name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdBy.name',
      label: 'Created by',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'credentialId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Description',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Created at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};
export const FILTER_ACCOUNT_LIST = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Организация',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'accountId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'totalAssets',
      label: 'Активов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'totalUsers',
      label: 'Пользователей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'createdAt',
      label: 'Создано',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Account',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'accountId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'totalAssets',
      label: 'Assets',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'totalUsers',
      label: 'Users',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'createdAt',
      label: 'Created',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const FILTER_WHITE_LIST = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Название',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdBy.name',
      label: 'Кем создан',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Дата создания',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'isDisabled',
      label: 'Статус',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'bulletins.bulletinId',
      label: 'ID Бюллетеня',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'totalBulletins',
      label: 'Бюллетеней',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetIds',
      label: 'ID актива',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdBy.name',
      label: 'Сreated by',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Created at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'isDisabled',
      label: 'Status',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'bulletins.bulletinId',
      label: 'Bulletin ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'accountId',
      label: 'Organization ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'totalBulletins',
      label: 'Bulletins count',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'assetIds',
      label: 'Asset ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};

export const FILTER_SERVER_LIST = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'url',
      label: 'Url',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Тип',
      filter: FilterServerType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'totalUsers',
      label: 'Пользователей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'account.name',
      label: 'Организация',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Добавлен',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'accountId',
      label: 'ID организации',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'credential.name',
      label: 'Учетная запись',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'url',
      label: 'Url',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Type',
      filter: FilterServerType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'totalUsers',
      label: 'Users',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'account.name',
      label: 'Organization',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Created',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'accountId',
      label: 'Account ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'credential.name',
      label: 'Credential',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};

export const FILTER_SERVER_LIST_FORM = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'url',
      label: 'Url',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Тип',
      filter: FilterServerType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Организация',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Добавлен',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'credential.name',
      label: 'Учетная запись',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'url',
      label: 'Url',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Type',
      filter: FilterServerType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'account.name',
      label: 'Organization',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Created',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'credential.name',
      label: 'Credential',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ],
};
export const FILTER_USER_LIST = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'userId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'auth.login',
      label: 'Логин (локальный)',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'auth.uid',
      label: 'Uid (LDAP)',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'auth.username',
      label: 'Имя пользователя (AD)',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'email',
      label: 'Email',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'role',
      label: 'Роль',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'isDisabled',
      label: 'Статус',
      filter: FilterUserStatus,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Тип',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Создано',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'lastActivityTime',
      label: 'Последняя активность',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'userId',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'auth.login',
      label: 'Login (local)',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'auth.uid',
      label: 'Uid (LDAP)',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'auth.username',
      label: 'User name (AD)',
      filter: TextFilter,
      operators: FILTER_TEXT_CONTAINS.text,
    },
    {
      name: 'email',
      label: 'Email',
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Type',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'role',
      label: 'Role',
      filter: FilterDeviceAndUser,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'isDisabled',
      label: 'Status',
      filter: FilterUserStatus,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'createdAt',
      label: 'Created',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'lastActivityTime',
      label: 'Last activity',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};
