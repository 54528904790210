/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { useQuery } from 'react-query';
import { Loader } from '@progress/kendo-react-indicators';
import { ErrorBoundary } from 'react-error-boundary';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import localeITask from '../../../../utils/i18n/taskLocale/inventorizationTask.json';
import '../TaskDescription.module.scss';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTaskActionsId, getTaskInventorizationId } from '../../../../services/task-service';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { TabsIDetails } from './iDetailsComponents/TabsIDetails';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { AddTask } from '../form/AddTask';
import { useConvertToJson } from '../../../../hooks/components/useConvertToJson';
import { useRepeatTask } from '../../../../hooks/components/useRepeatTask';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import { MetaBlock } from '../../../common/MetaBlock';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeITask;

export function TaskIDetails(): React.ReactElement {
  const { createNotification } = useNotificationContext();

  const { isOpenDialog } = useTaskContext();

  const { inventorizationId } = useParams<{ taskExecutionId: string; inventorizationId: string }>();

  const taskActionId = useRef<string | undefined>();
  const hostName = useRef<string | null>();
  const hostNameImage = useRef<string | unknown>();

  const queryInventorizationId = useQuery<
    Awaited<ReturnType<typeof getTaskInventorizationId>>,
    ApiError
  >(['inventorization', inventorizationId], () => getTaskInventorizationId(inventorizationId), {
    onSuccess: (data) => {
      taskActionId.current =
        data.type === 'linux' ||
        data.type === 'windows' ||
        data.type === 'router' ||
        data.type === 'firewall' ||
        data.type === 'switch' ||
        data.type === 'hypervisor'
          ? data.taskActionId
          : undefined;
      hostName.current =
        (data.type === 'linux' ||
          data.type === 'windows' ||
          data.type === 'router' ||
          data.type === 'firewall' ||
          data.type === 'switch' ||
          data.type === 'hypervisor') &&
        data.result.system_info?.hostname
          ? data.result.system_info?.hostname
          : '';
    },
  });

  const { data } = queryInventorizationId;

  const name =
    queryInventorizationId.data?.class === 'partial'
      ? `${localeITask[currentLocale].titleFull} ${hostNameImage.current}`
      : `${localeITask[currentLocale].titleFull} ${hostName.current}`;

  const { repeatTaskDetails } = useRepeatTask();

  const queryTaskExecutionId = useQuery<Awaited<ReturnType<typeof getTaskActionsId>>, ApiError>(
    ['taskActions', taskActionId.current],
    () => getTaskActionsId(taskActionId.current),
    {
      onSuccess: (response) => {
        hostNameImage.current =
          response.assets &&
          response.assets[0].type === 'host' &&
          response.assets[0].latestInventorization?.hostname;
      },
      enabled: Boolean(taskActionId.current),
    },
  );

  useEffect(() => {
    if (queryInventorizationId.error) {
      handleError(queryInventorizationId.error, createNotification);
    }
  }, [queryInventorizationId.isError, queryInventorizationId.error, createNotification]);

  const { createReportTaskDetails } = useCreateTask();

  const taskName =
    queryInventorizationId.data?.class === 'partial'
      ? `${localeITask[currentLocale].titleImage} ${hostNameImage.current}`
      : `${localeITask[currentLocale].titleFull} ${hostName.current}`;

  const handle2Json = useConvertToJson(
    data,
    queryInventorizationId.data?.class === 'partial' ? hostNameImage.current : hostName.current,
  );

  return (
    <div>
      {isOpenDialog && <AddTask />}
      {queryTaskExecutionId.status === 'success' && queryInventorizationId.status === 'success' ? (
        <div className="taskdescription">
          <div className="common-header-page">
            <Typography.h3 className="title">{taskName}</Typography.h3>
            <div className="topactions">
              <Button
                // fillMode="flat"
                // icon="refresh"
                onClick={(): void =>
                  repeatTaskDetails(queryTaskExecutionId.data, 'inventorization', name)
                }
              >
                {localeITask[currentLocale].btnRepeat}
              </Button>
              {data?.type !== 'firewall' && data?.type !== 'router' && data?.type !== 'switch' && (
                <Button onClick={(): void => createReportTaskDetails(queryTaskExecutionId.data)}>
                  {localeITask[currentLocale].btnCreateReport}
                </Button>
              )}
              <div className="json-actions">
                <Menu hoverOpenDelay={0} onSelect={handle2Json}>
                  <MenuItem text={localeITask[currentLocale].techDetails}>
                    <MenuItem text={localeITask[currentLocale].auditResult} />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <MetaBlock taskAction={queryTaskExecutionId.data} />
            <TabsIDetails data={data} hostName={hostName.current} />
          </ErrorBoundary>
        </div>
      ) : (
        <Loader
          style={{ color: 'black', marginLeft: '50%' }}
          size="large"
          type="converging-spinner"
        />
      )}
    </div>
  );
}
