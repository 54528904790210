import type { LdapDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import localeUser from '../../../../utils/i18n/userLocales/user.json';

export function TableFServerMetaBlock({
  data,
  currentLocale,
}: {
  data: LdapDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.name}</span>
          </td>
          <td>{data?.name || '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.url}</span>
          </td>
          <td>{data?.url}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.type}</span>
          </td>
          <td>{data?.type || '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.ldapId}</span>
          </td>
          <td>{data?.ldapId}</td>
        </tr>
      </tbody>
    </table>
  );
}
