/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import localeNewTask from '../../../../../utils/i18n/taskLocale/newTask.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function StatusCell(props: GridCellProps): React.ReactElement {
  const { dataItem, field, className, style } = props;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  return (
    <td
      title={
        className && className.includes('title') && field === 'isImported' && value === true
          ? localeNewTask[currentLocale].taskSyncDescription.result.statusAdd
          : localeNewTask[currentLocale].taskSyncDescription.result.statusNotAdd
      }
      className={className || ''}
      style={style}
    >
      {field === 'isImported' && value === true ? (
        <span className="k-icon k-i-check success" />
      ) : (
        <span className="k-icon k-i-close k-i-x fail" />
      )}
    </td>
  );
}
