/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton } from '@progress/kendo-react-inputs';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { NEWTASK_CHECK_DUPLICATE } from '../../../../utils/helpers/constants';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import styles from './NewTask.module.scss';
import { CommonTooltip } from '../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function LdapCheckDuplicate(): React.ReactElement {
  const { handleCheckDuplicate, checkDuplicate } = useTaskAddContext();

  return (
    <div className="newtask__time">
      <div className={styles.titleTaskWrapTooltip}>
        <label className="k-label">{localeNewTask[currentLocale].form.checkDuplicate.title}</label>
        <CommonTooltip>
          <div>
            <div>
              <span>{localeNewTask[currentLocale].form.synchronize.checkDuplicate}</span>
            </div>
            <ul className={styles.tooltipList}>
              <li>
                <span className={styles.itemTitle}>
                  {localeNewTask[currentLocale].form.synchronize.duplicateTitle1}
                </span>
                <span>{localeNewTask[currentLocale].form.synchronize.duplicatecontent1}</span>
              </li>
              <li>
                <span className={styles.itemTitle}>
                  {localeNewTask[currentLocale].form.synchronize.duplicateTitle2}
                </span>
                <span>{localeNewTask[currentLocale].form.synchronize.duplicatecontent2}</span>
              </li>
              <li>
                <span className={styles.itemTitle}>
                  {localeNewTask[currentLocale].form.synchronize.duplicateTitle3}
                </span>
                <span>{localeNewTask[currentLocale].form.synchronize.duplicatecontent3}</span>
              </li>
            </ul>
          </div>
        </CommonTooltip>
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="CheckDuplicate"
          value="globalCheck"
          checked={checkDuplicate === 'globalCheck'}
          label={NEWTASK_CHECK_DUPLICATE[currentLocale][0].label}
          onChange={handleCheckDuplicate}
        />
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="CheckDuplicate"
          value="inGroupCheck"
          checked={checkDuplicate === 'inGroupCheck'}
          label={NEWTASK_CHECK_DUPLICATE[currentLocale][1].label}
          onChange={handleCheckDuplicate}
        />
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="CheckDuplicate"
          value="doNotCheck"
          checked={checkDuplicate === 'doNotCheck'}
          label={NEWTASK_CHECK_DUPLICATE[currentLocale][2].label}
          onChange={handleCheckDuplicate}
        />
      </div>
    </div>
  );
}
