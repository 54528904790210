/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type React from 'react';

export function TypeCell({ dataItem }: GridCellProps): React.ReactElement {
  return (
    <td>
      {dataItem.type === 'activedirectory'
        ? 'AD'
        : dataItem.type === 'ldap'
        ? 'LDAP'
        : dataItem.type}
    </td>
  );
}
