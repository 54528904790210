/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataStatus = [] as IDaraFilterSelect[];
export function FilterResultForSyncTask(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataStatus = [
    { text: currentLocale === 'ru-RU' ? 'Результат...' : 'Result...', val: '' },
    {
      text:
        currentLocale === 'ru-RU'
          ? 'Добавлен в существующую группу'
          : 'Added to existing asset group',
      val: 'Added to existing asset group',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Добавлен в новую группу' : 'Added to new asset group',
      val: 'Added to new asset group',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Добавлен без группировки' : 'Added without asset group',
      val: 'Added without asset group',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Не добавлен (найден дубликат)' : 'Not added (duplicate)',
      val: 'Not added (duplicate)',
    },
    {
      text:
        currentLocale === 'ru-RU' ? 'Не добавлен (лимит лицензии)' : 'Not added (license limit)',
      val: 'Not added (license limit)',
    },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataStatus[0]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
