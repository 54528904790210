import { Button } from '@progress/kendo-react-buttons';
import { getUserData } from '../../../../services/local-storage-service';
import type { LdapDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import localeUser from '../../../../utils/i18n/userLocales/user.json';
import formStyles from '../../Containers/form/Form.module.scss';
import styles from '../UserList.module.scss';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { SrvDescriptionContextMenu } from '../../../common/contextMenu/SrvDescriptionContextMenu';

export function TableSServerMetaBlock({
  data,
  currentLocale,
}: {
  data: LdapDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const currentUser = getUserData();

  const userRoleCondition =
    data?.createdBy.source === 'user' &&
    currentUser?.role === 'user' &&
    data.createdBy.id === currentUser.id;

  const { selectCredential } = useHostActions();

  return (
    <table className="column column-second">
      <tbody>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.createdAt}</span>
          </td>
          <td>{data?.createdAt && new Date(data?.createdAt).toLocaleString()}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.createdByName}</span>
          </td>
          <td>{data?.createdBy.source === 'user' && data.createdBy.name}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.totalUsers}</span>
          </td>
          <td>{data?.totalUsers}</td>
        </tr>
        <tr>
          <td>
            <span>{localeUser[currentLocale].serverDescription.credential}</span>
          </td>
          <td>
            {data &&
              data.credential?.credentialId &&
              (currentUser?.role === 'reader_user' ||
                (!userRoleCondition && currentUser?.role === 'user')) && (
                <div className="credential-block-content">
                  <div className="credential-block">
                    <div className="credential-name" title={data.credential.name}>
                      {data.credential.name}
                    </div>
                    {data.credential?.type === 'ldap' ? (
                      <span className="credential-icon">ldap</span>
                    ) : (
                      <span className="credential-icon">activedirectory</span>
                    )}
                  </div>
                </div>
              )}
            {data &&
              data.credential &&
              data.credential.credentialId &&
              (currentUser?.role === 'super_admin' ||
                currentUser?.role === 'account_admin' ||
                userRoleCondition) && (
                <div className="credential-block-content">
                  <div className="credential-block">
                    <div className="credential-name" title={data.credential.name}>
                      {data.credential.name}
                    </div>
                    {data.credential?.type === 'ldap' ? (
                      <span className="credential-icon">ldap</span>
                    ) : (
                      <span className="credential-icon">activedirectory</span>
                    )}
                  </div>
                  <SrvDescriptionContextMenu data={data} currentLocale={currentLocale} />
                </div>
              )}
            {!data?.credential?.credentialId &&
              (currentUser?.role === 'super_admin' ||
                currentUser?.role === 'account_admin' ||
                userRoleCondition) && (
                <Button
                  icon="plus"
                  fillMode="flat"
                  className={
                    data?.name
                      ? `${formStyles.add_button} ${styles.add_credential}`
                      : styles.add_credential
                  }
                  onClick={(): void =>
                    selectCredential({
                      name: data?.name,
                      ldapId: data?.ldapId,
                      accountId: data?.accountId,
                      credentialId: data?.credentialId,
                      assetType: 'server',
                      type: data?.type,
                    })
                  }
                >
                  {localeUser[currentLocale].serverDescription.addCredential}
                </Button>
              )}
            {(!data?.credential || !data?.credentialId) &&
              (currentUser?.role === 'reader_user' ||
                (!userRoleCondition && currentUser?.role === 'user')) && (
                <span>{localeUser[currentLocale].serverDescription.isNotUsed}</span>
              )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
