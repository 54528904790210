/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useServerContext } from '../../../../../hooks/useServerContext';
import type { LdapGroupResponse } from '../../../../../types/__generated/on-premise-solution/api/ldapGroupResponse.v1';
import localeNewTask from '../../../../../utils/i18n/taskLocale/newTask.json';
import type { TLdapGrFormValues } from '../../../../../utils/helpers/types';

export function CellNewGroup({
  dataItem: { commonName },
}: Omit<GridCellProps, 'commonName'> & {
  dataItem: LdapGroupResponse;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeNewTask;

  const { ldapGrForm, setLdapGrForm } = useServerContext();

  const handleNewGroup = (): void => {
    const mutableLdapGrForm = ldapGrForm?.map((g) => {
      if (g.ldapGroupName === commonName && !g.newGr) {
        return {
          ...g,
          assetGroupPolicy: 'create',
          newGr: commonName,
          assetGr: null,
        };
      }

      if (g.ldapGroupName === commonName && g.newGr) {
        return {
          ...g,
          newGr: null,
          assetGr: null,
          assetGroupPolicy: 'do_not_create',
        };
      }

      return g;
    }) as TLdapGrFormValues[];

    if (mutableLdapGrForm) setLdapGrForm(mutableLdapGrForm);
  };

  return (
    <td
      title={
        !ldapGrForm || !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
          ? localeNewTask[currentLocale].form.ldapTable.grid.newGrTooltip
          : ''
      }
    >
      <RadioButton
        checked={!!ldapGrForm?.find((g) => g.newGr && g.ldapGroupName === commonName)}
        onClick={handleNewGroup}
        disabled={
          !ldapGrForm || !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
        }
        style={{
          borderColor:
            !ldapGrForm ||
            !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
              ? '#ccc'
              : '',
        }}
      />
    </td>
  );
}
