import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useServerContext } from '../../../../hooks/useServerContext';
import type { LdapsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { IServerForm } from '../../../../utils/helpers/types';

// export function CellSelectServer(props: GridCellProps): React.ReactElement {
export function CellSelectServers({
  dataItem: { name },
  dataItem: { ldapId },
  dataItem: { url },
  dataItem: { credential },
}: Omit<GridCellProps, 'name' | 'ldapId' | 'url' | 'credential'> & {
  dataItem: LdapsDashboardResponse;
}): React.ReactElement {
  const { serverForm, setServerForm } = useServerContext();

  useEffect(() => {
    if (!serverForm) {
      setServerForm(serverForm);
    }
  }, [serverForm, setServerForm]);

  const addDevice = useCallback(
    ({
      serverName,
      id,
      urlSrv,
      credSrv,
    }: {
      serverName: string | null;
      id: string;
      urlSrv: string;
      credSrv?: IServerForm['credential'];
    }): void => {
      if (serverForm && serverForm.length > 0) {
        const index = serverForm?.findIndex((s) => s.ldapId === id);
        if (index !== -1) {
          const cutServerForm = serverForm.filter((server) => server.ldapId !== id);
          setServerForm([...cutServerForm]);
        } else {
          setServerForm([
            ...serverForm,
            {
              ldapId: id,
              name: serverName || undefined,
              url: urlSrv,
              credential: credSrv || undefined,
            },
          ]);
        }
      } else {
        setServerForm([
          {
            ldapId,
            name: serverName || undefined,
            url: urlSrv,
            credential: credSrv || undefined,
          },
        ]);
      }
    },
    [ldapId, serverForm, setServerForm],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={!!serverForm?.find((s) => s.ldapId === ldapId)}
        onClick={(): void =>
          addDevice({
            serverName: name || undefined,
            id: ldapId,
            urlSrv: url,
            credSrv: credential,
          })
        }
      />
    </td>
  );
}
