/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useEffect, useRef, useState } from 'react';

interface IInputRenderProps {
  tooltipText?: string;
  classForLabel?: string;
  id: string;
  label: string;
  tooltipPosition?: {
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
  };
}
export function InputTooltipForm(inputRenderProps: IInputRenderProps): React.ReactElement {
  const { tooltipText, classForLabel, label, id, tooltipPosition } = inputRenderProps;

  const [isTooltip, setTooltip] = useState<boolean>(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const hideTooltip = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setTooltip(false);
    }, 1000);
  };

  const showHideTooltip = (): void => {
    if (timer.current) clearTimeout(timer.current);
    setTooltip(true);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <label className="k-label" htmlFor={id}>
      <div className="tooltip-form-wrap">
        <span className={classForLabel}>{label}</span>
        <div onMouseOver={showHideTooltip} onMouseLeave={hideTooltip} style={{ marginLeft: '4px' }}>
          <span className="k-icon k-i-question" />
        </div>
        {isTooltip && (
          <div
            className="tooltip-input-text-wrap"
            style={tooltipPosition ? { ...tooltipPosition } : {}}
          >
            <span>{tooltipText}</span>
          </div>
        )}
      </div>
    </label>
  );
}
